import { gql } from "graphql-tag"

const CREATE_GROUP = gql`
mutation Mutation($input: CreateGroupInput!) {
    CreateGroup(input: $input) {
      _id
    }
  }
`


const DELETE_GROUP = gql`
mutation Mutation($deleteGroupId: ID!, $deletedBy: ID!) {
    DeleteGroup(id: $deleteGroupId, deletedBy: $deletedBy)
  }
`

const UPDATE_GROUP = gql`
mutation Mutation($input: UpdateGroupInput!, $updateGroupId: ID!) {
    UpdateGroup(input: $input, id: $updateGroupId) {
      _id
      sections {
        _id
      }
      title
    }
  }
`

const GET_GROUPS_BY_BRANCHID = gql`
query GroupsByBranchId($branchId: ID) {
  GroupsByBranchId(branchId: $branchId) {
    _id
    sections {
      _id
      sectionId
      title
    }
    title
    products {
      productId {
        _id
      }
      quantity
      title
    }
    banner {
      image
      moduleType
      _id
    }
  }
}
`




export {
    CREATE_GROUP,
    DELETE_GROUP,
    UPDATE_GROUP,
    GET_GROUPS_BY_BRANCHID
}
