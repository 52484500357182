import { gql } from "graphql-tag"

const GetOwnerOrganization = gql`
  query {
    GetOwnerOrganization {
      name
    }
  }
`
const GetLiveOrganization = gql`
  query Query {
    GetLiveOrganizations {
      _id
      logo
      name
      domain
      description
    }
  }
`
const CheckOrganization = gql`
  query GetOrganizationByNameorDomain($name: String!) {
    GetOrganizationByNameorDomain(name: $name) {
      name
    }
  }
`
const GET_ORG_BY_DOMAIN = gql`
query GetOrganizationByDomain($domain: String!) {
  GetOrganizationByDomain(domain: $domain) {
    _id
    domain
    name
    
  }
}
`

export { GetOwnerOrganization, CheckOrganization, GetLiveOrganization, GET_ORG_BY_DOMAIN }
