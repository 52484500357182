import { gql } from "graphql-tag"

const GET_ORDERS = gql`
  query Orders(
    $page: Int!
    $perPage: Int!
    $isTrash: Boolean
    $search: String
    $orderSource: OrderSource
  ) {
    Orders(
      page: $page
      perPage: $perPage
      isTrash: $isTrash
      search: $search
      orderSource: $orderSource
    ) {
      totalCount
      orders {
        _id
        orderType
        orderId
        paymentType
        totalAmount
        isPaid
        createdAt
        customerAddress
        status
        orderSource
        orderShippingAddress{
          city{
            value
            label
          }
          country{
            value
            label
          }
          state{
            value
            label
          }
          street
          zip
        }
        customerId {
          _id
          fullName
          phoneNum
        }
        branchId {
          _id
          title
          address {
            city{
              value
              label
            }
            country{
              value
              label
            }
            state{
              value
              label
            }
            street
            zip
          }
        }
        items {
          item {
            _id
            title
          }
          quantity
        }
      }
    }
  }
`

const GET_ORDERS_COUNT = gql`
query Query {
  OrdersCount {
    callOrders
    endDateCallOrders
    endDateLiveOrders
    endDatePreOrder
    liveOrders
    preOrders
    startDateCallOrders
    startDateLiveOrders
    startDatePreOrder
  }
}
`

const GET_ORDERS_BY_FILTER = gql`
  query OrdersByFilter(
    $page: Int!
    $perPage: Int!
    $isTrash: Boolean
    $filters: OrderFilters
    $orderSource: OrderSource
  ) {
    OrdersByFilter(
      page: $page
      perPage: $perPage
      isTrash: $isTrash
      filters: $filters
      orderSource: $orderSource
    ) {
      totalCount
      orders {
        _id
        orderType
        orderId
        paymentType
        totalAmount
        isPaid
        createdAt
        customerAddress
        status
        orderSource
        orderShippingAddress{
          city{
            value
            label
          }
          country{
            value
            label
          }
          state{
            value
            label
          }
          street
          zip
        }
        customerId {
          _id
          fullName
          phoneNum
        }
        branchId {
          _id
          title
          address {
            city{
              value
              label
            }
            country{
              value
              label
            }
            state{
              value
              label
            }
            street
            zip
          }
        }
        items {
          item {
            _id
            title
          }
          quantity
        }
      }
    }
  }
`

const GET_ORDER = gql`
query Invoice($invoiceId: ID!) {
  Invoice(id: $invoiceId) {
      _id
      orderType
      orderId
      paymentType
      totalAmount
      deliveryCharges
      discountedAmount
      isPaid
      deliveryDate
      rejectReason
      status
      customerAddress
      createdAt
      orderSource
      orderShippingAddress{
        city {
          value
          label
        }
        country {
          value
          label
        }
        state {
          value
          label
        }
        street
        zip
      }
      customerId {
        _id
        fullName
        email
        phoneNum
      }
      branchId {
        _id
        title
        branchCode
        address {
          city{
            value
            label
          }
          country{
            value
            label
          }
          state{
            value
            label
          }
          street
          zip
        }
      }
      items {
        item {
          _id
          price
          discountedPrice
          title
          image{
            image
            moduleType
          }
          toggleStatus
        }
        price
        quantity
      }
    }
  }
`

const GET_CALCULATED_ORDERS = gql`
  query CalculatedOrders {
    CalculatedOrders {
      totalCount
      totalTodayOrders
      totalCountOf30Days
      totalSales
      totalTodaySales
      total30DaysSales
      averageRecordsPerDay
      maxOrders
      repeatPurchasePercentage
      repeatPurchasePercentageOverall
      averageOrdersPerCustomer30DaysFreq
      averageOrdersPerCustomerFreq
      orderTypes
    }
  }
`
const GET_USERS_BRANCHES_AND_PRODUCTS_OF_ORGANIZATION = gql`
  query GetUsersAndProductsOfOrganization {
    GetAllCustomersOfOrganization {
      _id
      fullName
      phoneNum
    }
    GetAllActiveBraches {
      _id
      title
      branchCode
      operationalStatus
    }
  }
`

const GET_ALL_ORDER_STATS = gql`
  query GetOrderStats($year: Int) {
    GetOrderStats(year: $year) {
      orders
    }
  }
`
const GET_PAYMENT_TYPES_STATS = gql`
  query GetPaymentTypesStats($year: Int) {
    GetPaymentTypesStats(year: $year) {
      payments
    }
  }
`
const RETERIVE_ORDERS_ADDRESS = gql`
  query ReteriveOrdersAddress($isTrash: Boolean!, $date: String) {
    ReteriveOrdersAddress(isTrash: $isTrash, date: $date) {
      location {
        latitude
        longitude
      }
    }
  }
`

const GET_ORDER_TYPES_STATS = gql`
  query GetOrderTypesStats($year: Int) {
    GetOrderTypesStats(year: $year) {
      orders
    }
  }
`
const GET_REJECTED_ORDER_STATS = gql`
  query GetRejectedOrders($year: Int) {
    GetRejectedOrders(year: $year) {
      orders
    }
  }
`

const GET_ALL_ORDERS = gql`
query AllOrders($orgId: ID) {
  AllOrders(orgId: $orgId) {
    _id
    orderId
    customerId {
      _id
    }
    branchId {
      _id
    }
    items {
      quantity
      item {
        _id
      }
    }
    organizationId {
      _id
    }
    description
    orderType
    customerAddress
    totalAmount
    status
    paymentType
    isPaid
    deletedAt
    orderSource
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
  }
}
`

const GET_CUSTOMER_ORDER = gql`
query CustomerOrders($organizationID:String!, $page: Int!, $perPage: Int!, $search: String) {
  CustomerOrders(organizationID:$organizationID, page: $page, perPage: $perPage, search: $search) {
    totalCount
    orders {
      _id
      orderId
      branchId {
        _id
        title
      }
      status
      createdAt
      items {
        quantity
      }
    }
  }
}
`

const GET_CUSTOMER_ORDER_ITEMS = gql`
query CustomerOrderItems($orderId: ID!) {
  CustomerOrderItems(id: $orderId) {
    items {
      item {
        title
        image {
          image
          moduleType
        }
        SKU
        price
        discountedPrice
      }
      quantity
    }
  }
}
`

export {
  GET_ORDERS,
  GET_ORDERS_BY_FILTER,
  GET_ORDER,
  GET_USERS_BRANCHES_AND_PRODUCTS_OF_ORGANIZATION,
  GET_CALCULATED_ORDERS,
  GET_ALL_ORDER_STATS,
  GET_PAYMENT_TYPES_STATS,
  RETERIVE_ORDERS_ADDRESS,
  GET_ORDER_TYPES_STATS,
  GET_REJECTED_ORDER_STATS,
  GET_ALL_ORDERS,
  GET_ORDERS_COUNT,
  GET_CUSTOMER_ORDER,
  GET_CUSTOMER_ORDER_ITEMS
}
