import { gql } from "graphql-tag"

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    CreateOrder(input: $input) {
      _id
    }
  }
`
const CREATE_LIVE_ORDER_MUTATION = gql`
  mutation CreateLiveOrder($input: CreateLiveOrderInput!) {
    CreateLiveOrder(input: $input) {
      _id
    }
  }
`
const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($orderId: ID!, $input: UpdateOrderInput!) {
    UpdateOrder(id: $orderId, input: $input) {
      _id
    }
  }
`

const DELETE_ORDER_MUTATION = gql`
  mutation DeleteOrder($deleteOrderId: ID!) {
    DeleteOrder(id: $deleteOrderId)
  }
`

export {
  CREATE_ORDER_MUTATION,
  UPDATE_ORDER_MUTATION,
  DELETE_ORDER_MUTATION,
  CREATE_LIVE_ORDER_MUTATION,
}
