import PropTypes from "prop-types"
import React, {useEffect} from "react"
import { useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import "react-toastify/dist/ReactToastify.css"
import 'react-phone-number-input/style.css'
// Import scss
import "./assets/scss/theme.scss"
import { ToastContainer } from "react-toastify"
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { Helmet } from "react-helmet"
import { GET_SEO_SETTINGS_BY_ORG_ID } from "graphql/queries/seoSettings"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_ORG_BY_DOMAIN } from "graphql/queries/organization"
import { GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const Layout = getLayout(layoutType)


  const getSubdomain = () => {
    const hostname = window.location.hostname
    const parts = hostname.split(".")
    if (parts.length >= 2) {
      return parts[0]
    }
    return ""
  }

  const subdomain = getSubdomain()
  localStorage.setItem("orgDomain",subdomain)

  //get org by domain

  var {
    loading,
    error,
    data: orgData,
    execute,
  } = useCustomQuery(GET_ORG_BY_DOMAIN, {
    variables: { domain: subdomain },
  })

  useEffect(() => {
    if (orgData) {
      localStorage.setItem(
        "organizationName",
        orgData?.GetOrganizationByDomain[0]?.name
      )
      localStorage.setItem(
        "organizationId",
        orgData?.GetOrganizationByDomain[0]?._id
      )
    }
  }, [orgData])

  var { loading, error, data, execute } = useCustomQuery(
    GET_CONFIGURATIONS_OF_ORG_NAME,
    {
      variables: { orgName: subdomain },
    }
  )

  useEffect(() => {
    if (data) {
      const siteConfigData = data?.GetSettingsOfOrganizationName
      sessionStorage.setItem("siteSettings", JSON.stringify(siteConfigData[0]))
      
      const colors = siteConfigData[0]
      document.body.style.setProperty('--theme-accent-bg', colors?.themeColors[0]?.value);
      document.body.style.setProperty('--theme-accent-text', colors?.themeColors[1]?.value);
      document.body.style.setProperty('--theme-button-bg', colors?.themeColors[2]?.value);
      document.body.style.setProperty('--theme-button-text', colors?.themeColors[3]?.value);
      document.body.style.setProperty('--theme-button-hover-bg', colors?.themeColors[4]?.value);
      document.body.style.setProperty('--theme-button-hover-text', colors?.themeColors[5]?.value);
      document.body.style.background = colors?.themeColors[0]?.value;
      document.body.style.color = colors?.themeColors[1]?.value;
    }
  }, [data])




  const getOrgId = localStorage.getItem("organizationId")

  var {
    loading,
    error,
    data: seoData,
    execute,
  } = useCustomQuery(GET_SEO_SETTINGS_BY_ORG_ID, {
    variables: { organizationId: getOrgId },
  })

  const pageTitle = seoData?.GetSeoSettingsOfOrgId?.metaTitle
  const pageDescription = seoData?.GetSeoSettingsOfOrgId?.metaDescription
  const pageKeywords = seoData?.GetSeoSettingsOfOrgId?.keyword // Comma-separated keywords
  const mainHeading = seoData?.GetSeoSettingsOfOrgId?.h1Heading

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
      <ToastContainer position="top-right" autoClose={1000} closeOnClick />
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
