import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import { useDispatch } from "react-redux"
import imgDefault from "../../../src/assets/images/demi_food.jpg"
import {
  addProductSuccess,
  getSubTotalPrice,
  toggleCartSidebar,
  toggleOnFavorite,
} from "store/actions"
import { CREATE_FAVORITE_PRODUCTS_MUTATION, UPDATE_FAVORITE_PRODUCTS_MUTATION } from "graphql/mutations/product"
import { useLazyQuery, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { GET_FAVORITE_PRODUCTS } from "graphql/queries/product"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, useParams } from "react-router-dom"
import { CURRENCY_SYMBOL } from "constants/modules"

const Card3 = ({
  product,
  title,
  image,
  tags,
  description,
  price,
  discountedPrice,
}) => {
  const dispatch = useDispatch()
  const [colors, setColors] = useState()
  const user = JSON.parse(localStorage.getItem("authUser"))
  const getOrgName = localStorage.getItem("organizationName")
  const productColors = sessionStorage.getItem("siteSettings")
  const organizationId = localStorage.getItem("organizationId")

  useEffect(() => {
    if (!user) {
      jQuery(`.wishlist`).removeClass("active")
    }
  }, [user])
  useEffect(() => {
    if (productColors == undefined) {
      setColors([])
    } else {
      setColors(JSON.parse(productColors))
    }
  }, [productColors])
  const getImageSrc = (img) => {
    if (
      img?.image === undefined ||
      img?.image == "" ||
      img?.image == null
    ) {
      return imgDefault
    } else {
      return `${process.env.REACT_APP_APOLLO_SERVER_URL}/${getOrgName}/${img?.moduleType}/${img?.image}`
    }
  }
  const [
    getFavoriteProducts,
    {
      loading: favoriteProductsLoading,
      error: favoriteProductsError,
      data: favoriteProducts,
    },
  ] = useLazyQuery(GET_FAVORITE_PRODUCTS)

  useEffect(() => {
    if (user) {
      getFavoriteProducts({
        variables: {
          userId: user?._id,
          orgId: organizationId,
        },
      })
    }
  }, [getFavoriteProducts])

  useEffect(() => {
    if (user) {
      const favList = favoriteProducts?.GetFavoriteProducts
      if (favList?.length > 0) {
        const isFavorite = favList.some(item => item.productId === product?._id)
        if (isFavorite) {
          jQuery(`.product-${product?._id.slice(-5)}`).addClass("active")
        } else {
          jQuery(`.product-${product?._id.slice(-5)}`).removeClass("active")
        }
      }
    }
  }, [favoriteProducts, product, user])


  const [CreateFavoriteProduct, { loading: createFavoriteProductLoading }] =
    useMutation(CREATE_FAVORITE_PRODUCTS_MUTATION)

  const [UpdateFavoriteProduct, { loading: updateFavoriteProductLoading }] =
    useMutation(UPDATE_FAVORITE_PRODUCTS_MUTATION)

  const handleClick = async () => {
    const formData = {
      productId: product?._id,
      createdBy: user._id,
      isFavorite: true,
      createdAt: new Date().toISOString(),
    }
    const result = await CreateFavoriteProduct({
      variables: {
        input: formData,
      },
    })
    if (result) {
      jQuery(`.product-${product?._id.slice(-5)}`).addClass("active")
      toast.success("Added to favorite successfully")
    } else {
      toast.error("Something went wrong")
    }
  }

  const openLoginModel = () => {

    const activeClassExists = jQuery(`.product-${product?._id.slice(-5)}`)

    if (!user) {
      dispatch(toggleOnFavorite(true))
    } else if (user && !activeClassExists.hasClass("active")) {
      handleClick()
    } else {
      const result = UpdateFavoriteProduct({
        variables: {
          updateFavoriteProductId: product?._id,
        },
      })
      if (result) {
        jQuery(`.product-${product?._id.slice(-5)}`).removeClass("active")
        toast.success("Removed from favorite successfully")
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  const myCardVariables = {
    Background:
      colors?.productColors?.length > 0
        ? colors?.productColors[0]?.value
        : "#ff7600",
    TitleText:
      colors?.productColors?.length > 0 ? colors?.productColors[1]?.value : "",
    DescriptionText:
      colors?.productColors?.length > 0 ? colors?.productColors[2]?.value : "",
    PriceText:
      colors?.productColors?.length > 0 ? colors?.productColors[3]?.value : "",
    AddToCart:
      colors?.productColors?.length > 0
        ? colors?.productColors[4]?.value
        : "#ff7600",
    AddToCartText:
      colors?.productColors?.length > 0
        ? colors?.productColors[5]?.value
        : "#fff",
  }

  const settings = {
    // Slick settings go here
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
  };

  const percentDiscount = ((price - discountedPrice) / price) * 100

  return (
    <>
      <Card
        className="product-block card3-product card-3"
        style={{
          "--bg-color": myCardVariables.Background,
          "--title-color": myCardVariables.TitleText,
          "--descrip-color": myCardVariables.DescriptionText,
          "--price-color": myCardVariables.PriceText,
          "--btn-bg": myCardVariables.AddToCart,
          "--btn-color": myCardVariables.AddToCartText,
        }}
      >
        {tags ? (
          <span className="featured text-white">{tags}</span>
        ): null}
        {discountedPrice ? (
          <span className="onsale">{Math.round(percentDiscount)}% off</span>
        ): null}
        <Button
          onClick={() => {
            openLoginModel()
          }}
          className={`${
            user ? `product-${product?._id.slice(-5)}` : ""
          } wishlist bx bxs-heart`}
        />
          <Slider {...settings}>
            {image?.map((img)=> {
              return(
                <div id="img-holder" key={img?._id}>
                  <Card.Img className="img-top1" variant="top" src={getImageSrc(img)} />
                </div>  
              )
            })}
          </Slider>
        <a
         
          className="add-to-cart button"
        >
          <i className="bx bx-plus"></i>
        </a>
        <Card.Body className="d-flex justify-content-between flex-column">
          <div>
            <Card.Title className="card3-title">{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
          </div>
          <Link className="text-info" to={"/item/" + product?.title +"/"+ product?._id}>
            <div>
              {discountedPrice ? (
                <>
                  <span
                    className="amount discount-amount"
                  >
                    {CURRENCY_SYMBOL}. {price}
                  </span>
                  <span className="amount-discountedPrice">
                    {CURRENCY_SYMBOL}. {discountedPrice}
                  </span>
                </>
              ) : (
                <span className="amount">{CURRENCY_SYMBOL}. {price}</span>
              )}
            </div>
          </Link>
        </Card.Body>
      </Card>
    </>
  )
}

export default Card3
