import {
  GET_CART_PRODUCTS,
  GET_CART_PRODUCTS_SUCCESS,
  GET_CART_PRODUCTS_FAIL,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_FAIL,
  INC_PRODUCT,
  INC_PRODUCT_SUCCESS,
  INC_PRODUCT_FAIL,
  DEC_PRODUCT,
  DEC_PRODUCT_SUCCESS,
  DEC_PRODUCT_FAIL,
  GET_SUBTOTAL_PRICE,
  CLEAR_CART,
  TOGGLE_CART_SIDEBAR,
  TOGGLE_LOGIN_MODEL,
  TOGGLE_ON_FAVORITE,
} from "./actionTypes"

export const getCartProducts = () => ({
  type: GET_CART_PRODUCTS,
})

export const getCartProductsSuccess = products => ({
  type: GET_CART_PRODUCTS_SUCCESS,
  payload: products,
})

export const getCartProductsFail = error => ({
  type: GET_CART_PRODUCTS_FAIL,
  payload: error,
})

export const addProduct = product => ({
  type: ADD_PRODUCT,
  payload: product,
})

export const addProductSuccess = product => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product,
})

export const addProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
})

export const removeProduct = id => ({
  type: REMOVE_PRODUCT,
  payload: id,
})

export const removeProductSuccess = id => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload: id,
})

export const removeProductFail = error => ({
  type: REMOVE_PRODUCT_FAIL,
  payload: error,
})

export const incProduct = id => ({
  type: INC_PRODUCT,
  payload: id,
})

export const incProductSuccess = id => ({
  type: INC_PRODUCT_SUCCESS,
  payload: id,
})

export const incProductFail = error => ({
  type: INC_PRODUCT_FAIL,
  payload: error,
})

export const decProduct = id => ({
  type: DEC_PRODUCT,
  payload: id,
})

export const decProductSuccess = id => ({
  type: DEC_PRODUCT_SUCCESS,
  payload: id,
})

export const decProductFail = error => ({
  type: DEC_PRODUCT_FAIL,
  payload: error,
})

export const getSubTotalPrice = () => ({
  type: GET_SUBTOTAL_PRICE,
})

export const clearCart = () => ({
  type: CLEAR_CART,
})

export const toggleCartSidebar = isOpen => ({
  type: TOGGLE_CART_SIDEBAR,
  payload: isOpen,
})


export const toggleLoginModal = isOpen => ({
  type: TOGGLE_LOGIN_MODEL,
  payload: isOpen,
})

export const toggleOnFavorite = isOpen => ({
  type: TOGGLE_ON_FAVORITE,
  payload: isOpen,
})
