import { gql } from "graphql-tag"

const GET_BRANCHES = gql`
  query Branches(
    $page: Int!
    $perPage: Int!
    $isTrash: Boolean
    $search: String
  ) {
    Branches(
      page: $page
      perPage: $perPage
      isTrash: $isTrash
      search: $search
    ) {
      totalCount
      branches {
        _id
        address {
          city {
            value
            label
          }
          country {
            value
            label
          }
          state {
            value
            label
          }
          street
          zip
        }
        branchCode
        email
        phone
        pin {
          lat
          lng
        }
        status
        operationalStatus
        timezone
        title
        openingClosingTime {
          monday {
            close
            open
            isOn
          }
          tuesday {
            close
            open
            isOn
          }
          wednesday {
            close
            open
            isOn
          }
          thursday {
            close
            open
            isOn
          }
          friday {
            close
            open
            isOn
          }
          saturday {
            close
            open
            isOn
          }
          sunday {
            close
            open
            isOn
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`
const GET_TRASHED_BRANCHES = gql`
  query Branches($page: Int!, $perPage: Int!) {
    GetTrashBranches(page: $page, perPage: $perPage) {
      totalCount
      branches {
        _id
        address {
          city {
            value
            label
          }
          country {
            value
            label
          }
          state {
            value
            label
          }
          street
          zip
        }
        deletedAt
        deletedBy
        branchCode
        email
        phone
        pin {
          lat
          lng
        }
        status
        operationalStatus
        timezone
        title
        openingClosingTime {
          monday {
            close
            open
          }
          tuesday {
            close
            open
          }
          wednesday {
            close
            open
          }
          thursday {
            close
            open
          }
          friday {
            close
            open
          }
          saturday {
            close
            open
          }
          sunday {
            close
            open
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`
const GET_BRANCH = gql`
  query ($branchId: ID!) {
    Branch(id: $branchId) {
      _id
      branchCode
      timezone
      phone
      email
      title
      websiteUrl
      status
      operationalStatus
      address {
        street
        city {
          value
          label
        }
        state {
          value
          label
        }
        country {
          value
          label
        }
        zip
      }
      pin {
        lat
        lng
      }
      openingClosingTime {
        thursday {
          close
          open
          isOn
        }
        monday {
          close
          open
          isOn
        }
        saturday {
          close
          open
          isOn
        }
        sunday {
          close
          open
          isOn
        }
        tuesday {
          close
          open
          isOn
        }
        wednesday {
          close
          open
          isOn
        }
        friday {
          close
          open
          isOn
        }
      }
    }
  }
`

const GET_BRANCH_AVAILABLE_STATUS = gql`
  query GetBranchStatus {
    BranchAvailableStatus {
      status
    }
  }
`
const GET_TRASH_BRANCHES_COUNT = gql`
  query GetTrashBranchesCount {
    GetTrashBranchesCount {
      count
    }
  }
`

const GET_ALL_ACTIVE_BRANCHES = gql`
  query GetAllActiveBraches {
    GetAllActiveBraches {
      _id
      title
      branchCode
      operationalStatus
    }
  }
`

const GET_ALL_LIVE_ACTIVE_BRANCHES = gql`
  query GetAllLiveActiveBranches {
    GetAllLiveActiveBranches {
      _id
      title
      branchCode
      email
      phone
      websiteUrl
      status
      operationalStatus
      timezone
      deletedAt
      createdBy
      updatedBy
      deletedBy
      createdAt
      updatedAt
    }
  }
`

const GET_BRANCHES_BY_ORG_NAME = gql`
query GetAllActiveBrachesByOrgName($orgName: String!) {
  GetAllActiveBrachesByOrgName(orgName: $orgName) {
    _id
    scheduler {
      date
      firstHalfClose
      firstHalfOpen
      secondHalfClose
      secondHalfOpen
      status
    }
    address {
      city {
        value
        label
      }
      country {
        label
        value
      }
      lat
      lng
      state {
        label
        value
      }
      street
      zip
    }
    branchCode
    createdAt
    createdBy
    deletedAt
    deletedBy
    deliveryType
    dineIn
    email
    headerNotice
    operationalStatus
    organization {
      _id
    }
    phone
    pin {
      lat
      lng
    }
    priority
    status
    timezone
    title
    updatedAt
    updatedBy
    websiteUrl
  }
}
`

const GET_HEADER_NOTICE = gql`
query GetHeaderNotice($getHeaderNoticeId: ID) {
  GetHeaderNotice(id: $getHeaderNoticeId) {
    _id
    headerNotice
  }
}
`

const GET_BRANCH_TIMINGS_BY_BRANCH_ID = gql`
query GetBranchSettingsByBranchId($getBranchSettingsByBranchIdId: ID!) {
  GetBranchSettingsByBranchId(id: $getBranchSettingsByBranchIdId) {
    _id
    branchId
    deliveryTiming {
      day
      firstHalfOpen
      firstHalfClose
      isChecked
      isDisabled
      secondHalfClose
      secondHalfOpen
    }
    globalTiming {
      day
      firstHalfClose
      firstHalfOpen
      isChecked
      isDisabled
      secondHalfClose
      secondHalfOpen
    }
    isSeparateTimings
    takeAwayTiming {
      day
      firstHalfClose
      firstHalfOpen
      isChecked
      isDisabled
      secondHalfClose
      secondHalfOpen
    }
    dineInTiming {
      day
      firstHalfClose
      firstHalfOpen
      isChecked
      isDisabled
      secondHalfClose
      secondHalfOpen
    }
  }
}
`

export {
  GET_BRANCH,
  GET_BRANCHES,
  GET_BRANCH_AVAILABLE_STATUS,
  GET_TRASHED_BRANCHES,
  GET_TRASH_BRANCHES_COUNT,
  GET_ALL_ACTIVE_BRANCHES,
  GET_ALL_LIVE_ACTIVE_BRANCHES,
  GET_BRANCHES_BY_ORG_NAME,
  GET_HEADER_NOTICE,
  GET_BRANCH_TIMINGS_BY_BRANCH_ID
}
