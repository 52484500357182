import React from 'react'

export const PIN_ICON = <svg width="46" height="46" viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M7.1875 18.2083C7.1875 9.47528 14.267 2.39581 23 2.39581C31.733 2.39581 38.8125 9.47528 38.8125 18.2083C38.8125 24.9469 34.9534 31.2639 31.3041 35.7647C29.4574 38.0423 27.6141 39.9159 26.2331 41.2202C25.5417 41.8732 24.9638 42.3857 24.5562 42.737C24.3523 42.9128 24.1908 43.0483 24.0788 43.1412C24.0228 43.1876 23.9791 43.2234 23.9487 43.2481L23.9131 43.277L23.9029 43.2852L23.8998 43.2877L23.8987 43.2886C23.8983 43.2889 23.898 43.2891 23 42.1666C22.102 43.2891 22.1017 43.2889 22.1013 43.2886L22.1002 43.2877L22.0971 43.2852L22.0869 43.277L22.0513 43.2481C22.0209 43.2234 21.9772 43.1876 21.9212 43.1412C21.8092 43.0483 21.6477 42.9128 21.4438 42.737C21.0362 42.3857 20.4583 41.8732 19.7669 41.2202C18.3859 39.9159 16.5426 38.0423 14.6959 35.7647C11.0466 31.2639 7.1875 24.9469 7.1875 18.2083ZM23 42.1666L22.102 43.2891C22.627 43.7091 23.373 43.7091 23.898 43.2891L23 42.1666ZM23 40.2797C23.3427 39.978 23.77 39.5919 24.2591 39.13C25.5734 37.8888 27.3239 36.1087 29.0709 33.954C32.6091 29.5902 35.9375 23.9281 35.9375 18.2083C35.9375 11.0631 30.1452 5.27081 23 5.27081C15.8548 5.27081 10.0625 11.0631 10.0625 18.2083C10.0625 23.9281 13.3909 29.5902 16.9291 33.954C18.6761 36.1087 20.4266 37.8888 21.7409 39.13C22.23 39.5919 22.6573 39.978 23 40.2797Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M15.8125 18.2083C15.8125 14.2388 19.0305 11.0208 23 11.0208C26.9695 11.0208 30.1875 14.2388 30.1875 18.2083C30.1875 22.1779 26.9695 25.3958 23 25.3958C19.0305 25.3958 15.8125 22.1779 15.8125 18.2083ZM23 13.8958C20.6183 13.8958 18.6875 15.8266 18.6875 18.2083C18.6875 20.59 20.6183 22.5208 23 22.5208C25.3817 22.5208 27.3125 20.59 27.3125 18.2083C27.3125 15.8266 25.3817 13.8958 23 13.8958Z" fill="#1C1B1E" />
</svg>

export const SEARCH_ICON = <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M2.34375 19.6875C2.34375 10.1088 10.1088 2.34375 19.6875 2.34375C29.2661 2.34375 37.0312 10.1088 37.0312 19.6875C37.0312 29.2661 29.2661 37.0312 19.6875 37.0312C10.1088 37.0312 2.34375 29.2661 2.34375 19.6875ZM19.6875 5.15625C11.6622 5.15625 5.15625 11.6622 5.15625 19.6875C5.15625 27.7129 11.6622 34.2188 19.6875 34.2188C27.7129 34.2188 34.2188 27.7129 34.2188 19.6875C34.2188 11.6622 27.7129 5.15625 19.6875 5.15625Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M13.3899 12.4523C15.0002 10.8421 17.2287 9.84375 19.6876 9.84375C22.1466 9.84375 24.3751 10.8421 25.9852 12.4523C26.5344 13.0015 26.5344 13.8919 25.9852 14.441C25.4361 14.9902 24.5457 14.9902 23.9965 14.441C22.8922 13.3368 21.3707 12.6563 19.6876 12.6563C18.0045 12.6563 16.483 13.3368 15.3786 14.4411C14.8294 14.9902 13.939 14.9902 13.3899 14.441C12.8407 13.8918 12.8407 13.0014 13.3899 12.4523Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M30.1511 30.1511C30.7003 29.6019 31.5907 29.6019 32.1399 30.1511L40.0948 38.106C40.644 38.6552 40.644 39.5456 40.0948 40.0948C39.5457 40.644 38.6553 40.644 38.1061 40.0948L30.1511 32.1398C29.602 31.5906 29.602 30.7003 30.1511 30.1511Z" fill="#1C1B1E" />
</svg>

export const CART_ICON = <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M4.21875 11.8125C4.21875 11.0358 4.84835 10.4062 5.625 10.4062H39.375C40.1516 10.4062 40.7812 11.0358 40.7812 11.8125V38.4375C40.7812 40.2496 39.3123 41.7188 37.5 41.7188H7.5C5.68778 41.7188 4.21875 40.2496 4.21875 38.4375V11.8125ZM7.03125 13.2187V38.4375C7.03125 38.6963 7.24115 38.9062 7.5 38.9062H37.5C37.7589 38.9062 37.9688 38.6964 37.9688 38.4375V13.2187H7.03125Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M9.81025 3.84691C10.0757 3.49095 10.4936 3.28125 10.9376 3.28125H34.0626C34.5066 3.28125 34.9245 3.49095 35.1899 3.84691L40.5024 10.9719C40.9667 11.5945 40.8383 12.4756 40.2157 12.9399C39.593 13.4041 38.712 13.2757 38.2477 12.6531L33.357 6.09375H11.6432L6.75245 12.6531C6.28821 13.2757 5.40713 13.4041 4.7845 12.9399C4.16187 12.4756 4.03348 11.5945 4.49772 10.9719L9.81025 3.84691Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M15.4165 16.5938C16.1932 16.5938 16.8228 17.2233 16.8228 18C16.8228 21.1661 19.3723 23.7187 22.4999 23.7187C25.6275 23.7187 28.1769 21.1661 28.1769 18C28.1769 17.2233 28.8065 16.5938 29.5832 16.5938C30.3598 16.5938 30.9894 17.2233 30.9894 18C30.9894 22.7038 27.1963 26.5312 22.4999 26.5312C17.8035 26.5312 14.0103 22.7039 14.0103 18C14.0103 17.2233 14.6399 16.5938 15.4165 16.5938Z" fill="#1C1B1E" />
</svg>

export const ME_ICON = <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M2.34375 22.5C2.34375 11.368 11.368 2.34375 22.5 2.34375C33.632 2.34375 42.6562 11.368 42.6562 22.5C42.6562 33.632 33.632 42.6562 22.5 42.6562C11.368 42.6562 2.34375 33.632 2.34375 22.5ZM22.5 5.15625C12.9213 5.15625 5.15625 12.9213 5.15625 22.5C5.15625 32.0787 12.9213 39.8438 22.5 39.8438C32.0787 39.8438 39.8438 32.0787 39.8438 22.5C39.8438 12.9213 32.0787 5.15625 22.5 5.15625Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M16.4062 16.875C16.4062 13.5095 19.1345 10.7812 22.5 10.7812C25.8655 10.7812 28.5938 13.5095 28.5938 16.875C28.5938 20.2405 25.8655 22.9688 22.5 22.9688C19.1345 22.9688 16.4062 20.2405 16.4062 16.875ZM22.5 13.5938C20.6878 13.5938 19.2188 15.0628 19.2188 16.875C19.2188 18.6872 20.6878 20.1562 22.5 20.1562C24.3122 20.1562 25.7812 18.6872 25.7812 16.875C25.7812 15.0628 24.3122 13.5938 22.5 13.5938Z" fill="#1C1B1E" />
  <path fillRule="evenodd" clipRule="evenodd" d="M18.7499 28.5938C14.5283 28.5938 11.0726 31.8773 10.7988 36.0288C10.7477 36.8038 10.078 37.3906 9.30302 37.3395C8.52805 37.2883 7.94125 36.6187 7.99236 35.8437C8.363 30.2238 13.037 25.7812 18.7499 25.7812H26.2499C31.9553 25.7812 36.6246 30.2121 37.0061 35.8214C37.0588 36.5963 36.4734 37.2671 35.6985 37.3198C34.9237 37.3726 34.2528 36.7871 34.2001 36.0123C33.9182 31.8686 30.466 28.5938 26.2499 28.5938H18.7499Z" fill="#1C1B1E" />
</svg>
