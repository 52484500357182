import React, { useEffect, useState, useCallback, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations"
import { GET_ORG_BY_DOMAIN } from "graphql/queries/organization"
import PaginatedTable from "components/Table/PaginatedTable"
import { useLazyQuery } from "@apollo/client"
import { GET_CUSTOMER_ORDER } from "graphql/queries/order"
import Pagination from "components/Pagination"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import { toCapitalizeText } from "utils/transform"
import PuffLoading from "components/Loading/PuffLoading"

const OrderHistory = props => {
  document.title = "Order History"

  const navigate = useNavigate()
  const [theme, setTheme] = useState("1")
  const [organizationID, setOrganizationID] = useState("")
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")
  const user = JSON.parse(localStorage.getItem("authUser"))

  if (!user) return navigate('/')

  const getSubdomain = useCallback(() => {
    const hostname = window.location.hostname
    const parts = hostname.split(".")
    if (parts.length >= 2) {
      return parts[0]
    }
    return ""
  }, [])
  const subdomain = getSubdomain()
  var { loading, error, data: orgData, execute, } = useCustomQuery(GET_ORG_BY_DOMAIN, {
    variables: { domain: subdomain },
  })

  // Server side searching
  const [search, { loading: searchLoading, __, orderData }] =
    useLazyQuery(GET_CUSTOMER_ORDER, {
      onCompleted: data => {
        setOrders(data?.CustomerOrders?.orders || [])
        setTotalCount(data?.CustomerOrders?.totalCount)
      },
    })


  useEffect(() => {
    if (orgData) {
      localStorage.setItem(
        "organizationName",
        orgData?.GetOrganizationByDomain[0]?.name
      )
      localStorage.setItem(
        "organizationId",
        orgData?.GetOrganizationByDomain[0]?._id
      )
      setOrganizationID(orgData?.GetOrganizationByDomain[0]?._id)
    }
  }, [orgData])

  var { loading, error, data, execute } = useCustomQuery(
    GET_CONFIGURATIONS_OF_ORG_NAME,
    {
      variables: { orgName: subdomain },
    }
  )

  useEffect(() => {
    if (data) {
      const siteConfigData = data?.GetSettingsOfOrganizationName
      sessionStorage.setItem("siteSettings", JSON.stringify(siteConfigData[0]))
      setTheme(siteConfigData[0]?.theme)
    }
  }, [data])


  useEffect(() => {
    if (organizationID) {
      search({
        variables: {
          organizationID,
          page: page,
          perPage: perPage,
          search: searchTerm?.trim(),
        },
      })
    }
  }, [organizationID])

  // First time load all data with pagination
  useEffect(() => {
    search({
      variables: {
        organizationID,
        page: page,
        perPage: perPage,
        search: searchTerm?.trim(),
      },
    })
  }, [debouncedSearchTerm, page, perPage])

  // set debounce value with timer delay
  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 1000)

    return () => {
      clearTimeout(delay)
    }
  }, [searchTerm])


  const columns = useMemo(
    () => [
      {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ value }) => {
          return (
            <span className="font-size-12 badge-soft-dark badge bg-secondary text-uppercase">
              {value}
            </span>
          )
        },
      },
      {
        Header: "Items",
        accessor: "items",
        Cell: ({ value }) => {
          const quantity = useMemo(() => {
            return value?.reduce((accumulator, item) => accumulator + item.quantity, 0);
          }, [value]);

          return (
            <span>{quantity}</span>
          )
        },
      },
      {
        Header: "Order Time",
        accessor: "createdAt",
        Cell: ({ value }) => {
          return (
            <span>
              {moment(
                isNaN(new Date(value).getTime())
                  ? Number(value)
                  : new Date(value)
              ).format("DD-MM-YYYY hh:MM:ss a")}
            </span>
          )
        },
      },
      {
        Header: "Order Status",
        accessor: "status",
        Cell: ({ value }) => {
          switch (value) {
            case "PAID":
              return (
                <span className="font-size-12 badge-soft-success badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "CANCELED":
              return (
                <span className="font-size-12 badge-soft-danger badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "REJECTED":
              return (
                <span className="font-size-12 badge-soft-dark badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "PENDING":
              return (
                <span className="font-size-12 badge-soft-warning badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "IN_PROGRESS":
              return (
                <span className="font-size-12 badge-soft-info badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "DELIVERED":
              return (
                <span className="font-size-12 badge-soft-primary badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "NOT_SUBMITTED":
              return (
                <span className="font-size-12 badge-soft-secondary text-white badge bg-dark">
                  {toCapitalizeText(value)}
                </span>
              )
            case "DISPUTED":
              return (
                <span className="font-size-12 badge-soft-secondary badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
            case "DISPATCHED":
              return (
                <span className="font-size-12 badge-soft-dark badge bg-secondary">
                  {toCapitalizeText(value)}
                </span>
              )
          }
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: (value) => {
          return <Link to={`/invoice-detail/${value.cell.value}`}>View Details</Link>
        },
      },
    ],
    [orders]
  )

  return (
    <React.Fragment>
      <div className="py-5 cart-page">
        <Container>
          <div className={totalCount <= perPage ? "mt-2" : "mt-3"}>
            <Row className="align-items-center">
              <Col md={4}>
                <div className="d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Orders History</h4>
                </div>
              </Col>
              <Col md={8}>
                <Pagination
                  totalRows={totalCount}
                  pageChangeHandler={setPage}
                  rowsPerPage={perPage}
                  setRowsPerPage={setPerPage}
                  currentPage={page}
                />
              </Col>
            </Row>

            <div className="position-relative">
              {searchLoading && <PuffLoading />}
              <PaginatedTable
                columns={columns}
                data={orders}
                isLoading={searchLoading}
              />
            </div>
            <Pagination
              totalRows={totalCount}
              pageChangeHandler={setPage}
              rowsPerPage={perPage}
              setRowsPerPage={setPerPage}
              currentPage={page}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(OrderHistory)
