import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { GET_ORDER } from "graphql/queries/order"
import { useLazyQuery } from "@apollo/client"
import PuffLoading from "components/Loading/PuffLoading"
import moment from "moment"
import { AuthContext } from "context/AuthContext"
import { toCapitalizeText } from "utils/transform"
import withRouter from "components/Common/withRouter"
import { CURRENCY_SYMBOL } from "constants/modules"

const InvoiceDetail = props => {
  const navigate = useNavigate()

  const params = useParams()
  document.title = "Invoice Detail"

  const [order, setOrder] = useState({})
  const [subTotalPrice, setSubTotalPrice] = useState(0)
  const { user } = useContext(AuthContext)

  const [getSingleOrder, { loading, __, data: orderData }] =
    useLazyQuery(GET_ORDER)

  useEffect(() => {
    if (params.id) {
      getSingleOrder({
        variables: {
          invoiceId: params?.id,
        },
      })
    }
  }, [params.id])

  useEffect(() => {
    if (orderData) {
      setOrder(orderData?.Invoice)
      setSubTotalPrice(orderData?.Invoice.items.reduce((accumulator, currentItem) => {
        const itemSubtotal = currentItem.quantity *  currentItem.price;
        return accumulator + itemSubtotal;
      }, 0))
    }
  }, [orderData])


  const printOrderDetails = () => {
    window.print()
  }

  return (
    <React.Fragment>
      <div className="m-5">
        {loading && <PuffLoading />}

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Orders" breadcrumbItem="Order Detail" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="invoice-title mb-4 align-items-center">
                    <Col
                      md={6} className="mb-2"
                    >
                      <img
                        src="https://smartdemowp.com/snacky/wp-content/uploads/logo-light.png"
                        alt="logo"
                        height="48"
                        style={{ marginRight: "10px" }}
                        className="bg-dark p-2 rounded rounded-3"
                      />
                    </Col>
                    <h4 className="font-size-16 col-md-6 justify-content-end d-flex">
                      <Badge
                        className="badge-soft-dark ms-1 fs-5 text-uppercase fw-bold"
                        color="success"
                      >
                        Order # {order?.orderId}
                      </Badge>
                    </h4>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <address>
                        <strong>Customer Name:</strong>
                        <br />
                        {order?.customerId?.fullName}
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <address>
                        <strong>Customer Email:</strong>
                        <br />
                        {order?.customerId?.email}
                      </address>
                      <address>
                        <strong>Billed To:</strong>
                        <br />
                        {order?.customerAddress}
                      </address>
                    </Col>
                    <Col sm="6" className="text-sm-end">
                      <address>
                        <strong>Customer Phone:</strong>
                        <br />
                        {order?.customerId?.phoneNum}
                      </address>
                      <address>
                        <strong>Shipped To:</strong>
                        <br />
                        {order?.customerAddress}
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" className="mt-3">
                      <address
                        style={{ textDecoration: "capitalize !important" }}
                      >
                        <strong>Payment Method:</strong>
                        <br />
                        <span>{toCapitalizeText(order?.paymentType)}</span>
                        <br />
                      </address>
                    </Col>
                    <Col sm="6" className="mt-3 text-sm-end">
                      <address>
                        <strong>Order Date:</strong>
                        <br />
                        {moment(Number(order?.createdAt)).format(
                          "DD-MM-YY HH:MM:SS a"
                        )}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Order summary</h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th className="text-end">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(order?.items, (item, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item?.item?.title}</td>
                            <td>{item?.quantity}</td>
                            <td className="text-end">
                              {CURRENCY_SYMBOL}
                              {item?.price?.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row>
                    <Col md={4} sm={12}>
                    </Col>
                    <Col md={8} sm={12}>
                    <div className="table-responsive">
                    <Table className="table-nowrap">
                      <tbody>
                        <tr>
                          <td>
                            Sub Total
                          </td>
                          <td className="text-end">
                            {CURRENCY_SYMBOL}
                            {subTotalPrice?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Delivery Charges
                          </td>
                          <td className="text-end">
                            {CURRENCY_SYMBOL}
                            {parseFloat(order?.deliveryCharges)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Discount Price
                          </td>
                          <td className="text-end">
                            - {CURRENCY_SYMBOL}
                            {order?.discountedAmount?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0">
                            <strong>Total</strong>
                          </td>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                              {CURRENCY_SYMBOL}
                              {order?.totalAmount?.toFixed(2)}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                    </Col>
                  </Row>
                  <div className="d-print-none">
                    <div className="float-end">
                      <Link
                        to="#"
                        onClick={printOrderDetails}
                        className="btn btn-primary page-btn"
                      >
                        <i className="fa fa-print" />
                        <span className="ms-1"> Print</span>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
