import "./index.scss"
import React, { useEffect, useState, useCallback, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Cart from "components/Cart"
import { Col, Input, Container, Row, Card, Alert, CardBody, Badge, Form, CardTitle, Label, FormGroup, FormFeedback, FormText, CardHeader } from "reactstrap"
import Modal from "react-bootstrap/Modal"
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input"
//redux
import { useSelector, useDispatch } from "react-redux"
import { clearCart, getSubTotalPrice } from "store/actions"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import useCustomMutation from "graphql/hooks/useCustomMutation"
import { GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations"
import { GET_ORG_BY_DOMAIN } from "graphql/queries/organization"
import { CREATE_LIVE_ORDER_MUTATION } from "graphql/mutations/order"
import { GET_PROMO_CODES } from "graphql/queries/discount"
// Formik Validation
import { useFormik } from "formik"
import { toast } from "react-toastify"
import Select from "react-select"
import CountryRegion from "countryregionjs"
import cart_empty from "../../assets/images/cart-empty.svg"
//sockets
import io from "socket.io-client"
import { useLazyQuery, useMutation } from "@apollo/client"
const socket = io(process.env.REACT_APP_APOLLO_SERVER_URL)
import { checkoutTypes, CURRENCY_SYMBOL, loyaltyPointsType } from "constants/modules"
import Card2 from "components/Cards/Card2"
import Slider from "react-slick"
import { GET_PRODUCTS } from "graphql/queries/product"
import { GET_ALL_ORDERS } from "graphql/queries/order"
import { UPDATE_PROMO_CODE_COUNT } from "graphql/mutations/discount"
import { GET_BRANCH_TIMINGS_BY_BRANCH_ID, GET_BRANCHES_BY_ORG_NAME } from "graphql/queries/branch"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { ADD_LOYALTY_POINTS, UPDATE_LOYALTY_POINTS } from "graphql/mutations/loyaltyPoints"
import { GET_LOYALTY_PERCENTAGE } from "graphql/queries/loyaltyPercentage"
import { GET_LOYALTY_POINTS_BY_CUSTOMER } from "graphql/queries/loyaltyPoints"
import { customerData } from "common/data"
import { use } from "i18next"

const Items = props => {
  document.title = "Cart | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState('');
  const { cart, subTotalPrice } = useSelector(state => state.Cart)
  const [theme, setTheme] = useState("1")
  const [organizationName, setOrganizationName] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [orderId, setOrderId] = useState("")
  const [promoCode, setPromoCode] = useState('');
  const [grandTotal, setGrandTotal] = useState(subTotalPrice);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(100);
  const [paymentType, setPaymentType] = useState("") //'CASH_ON_DELIVERY', 'CREDIT_DEBIT_CARD', 'STRIPE', 'PAYPAL'
  const [locationCord, setLocationCord] = useState({ latitude: '', longitude: '' });
  const [modal_center, setmodal_center] = useState(false);
  const { fetchProducts } = useSelector(state => state.GetBranchProducts)


  const branchId = localStorage.getItem("branchId")
  const organizationNameId = localStorage.getItem("organizationName")
  const user = JSON.parse(localStorage.getItem("authUser"))
  const organizationId = localStorage.getItem("organizationId")

  const floatValue = (value) => {
    return parseFloat(value).toFixed(2);
  };

  var countryRegion = null

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: styles => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const getSubdomain = useCallback(() => {
    const hostname = window.location.hostname
    const parts = hostname.split(".")
    if (parts.length >= 2) {
      return parts[0]
    }
    return ""
  }, [])
  const subdomain = getSubdomain()

  const generateOrderId = length => {
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    var orderId = ""

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length)
      orderId += characters.charAt(randomIndex)
    }
    return orderId
  }

  const INITIAL_SHIPPING_VALUES = {
    fullName: "",
    email: "",
    phoneNum: "",
    zip: "",
    street: "",
    city: null,
    state: null,
    country: null,
    ship_zip: "",
    ship_street: "",
    ship_city: null,
    ship_state: null,
    ship_country: null,
  }
  const shippingInfo = localStorage.getItem("shippingInfo")
    ? JSON.parse(localStorage.getItem("shippingInfo"))
    : INITIAL_SHIPPING_VALUES

  const [state, setState] = useState(shippingInfo?.state)
  const [states, setStates] = useState([])
  const [country, setCountry] = useState(shippingInfo?.country)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(1000000)
  const [searchTerm, setSearchTerm] = useState("")
  const [countries, setCountries] = useState([])
  const [city, setCity] = useState(shippingInfo?.city)
  const [cities, setCities] = useState([])
  const [ship_state, setShipState] = useState(shippingInfo?.ship_state)
  const [ship_country, setShipCountry] = useState(shippingInfo?.ship_country)
  const [ship_city, setShipCity] = useState(shippingInfo?.ship_city)
  const [orders, setOrders] = useState([])
  const [items, setItems] = useState([])
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [branches, setBranches] = useState([])
  const [branchTimings, setBranchTimings] = useState([])
  const [branchOpen, setBranchOpen] = useState(true)
  const [branchOpenTime, setBranchOpenTime] = useState("")
  const [branchCloseTime, setBranchCloseTime] = useState("")
  const [dateTime, setDateTime] = useState("")
  const [checkoutEnable, setCheckoutEnable] = useState(false)
  const [isLiveOrder, setIsLiveOrder] = useState(false)
  const [loyaltyPoints, setLoyaltyPoints] = useState(0)
  const [loyaltyPointCheck, setloyaltyPointCheck] = useState(false)
  const [loyaltyPercentage, setLoyaltyPercentage] = useState(0)
  const totalAmount = (parseFloat(grandTotal) + parseFloat(deliveryCharges)).toFixed(2)


  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "cash_on_delivery") {
      setPaymentType("CASH_ON_DELIVERY")
    } else if (option === "card_payment") {
      setPaymentType("CREDIT_DEBIT_CARD")
    } else {
      setPaymentType("CASH_ON_DELIVERY")
    }
  };

  useEffect(() => {
    if (orderId && !cart.length === 0) {
      localStorage.removeItem("shippingInfo")
    }
  }, [orderId])


  const [formData, setFormData] = useState({
    email: shippingInfo?.email,
    fullName: shippingInfo?.fullName,
    phoneNum: shippingInfo?.phoneNum,
    street: shippingInfo?.street,
    zip: shippingInfo?.zip,
    ship_street: shippingInfo?.ship_street,
    ship_zip: shippingInfo?.ship_zip,
  })


  var { loading, error, data: orgData, execute, } = useCustomQuery(GET_ORG_BY_DOMAIN, {
    variables: { domain: subdomain },
  })
  const [CreateLiveOrder, { loading: createOrderLoading }] = useCustomMutation(
    CREATE_LIVE_ORDER_MUTATION
  )
  const [UpdatePromoCodeCount, { loading: updatePromoCodeLoading }] = useCustomMutation(
    UPDATE_PROMO_CODE_COUNT
  )

  const [getPromoCodes, { data: promoCodes }] = useLazyQuery(GET_PROMO_CODES)

  const handlePromoCodeChange = (event) => {
    const enteredPromoCode = event.target.value;
    setPromoCode(enteredPromoCode);

    getPromoCodes({
      variables: {
        organizationId: organizationId,
        promoCode: enteredPromoCode
      }
    });
  };
  function isCurrentDateBetween(startTime, endTime) {
    const currentTimestamp = Date.now();

    return currentTimestamp >= startTime && currentTimestamp <= endTime;
  }
  const [promoCodeData, setPromoCodeData] = useState()
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const selectedBranch = JSON.parse(localStorage.getItem("selectedBranch"))
    const productIds = cart?.map((item) => item?._id);

    if (!cart?.length) return
    setGrandTotal(subTotalPrice);
    if (!promoCodes || promoCodes.GetPromoCode.length === 0) {
      setDiscountedAmount(0);
      return;
    }
    const code = promoCodes?.GetPromoCode[0];
    const currentBranch = code?.Branches?.find(branch => branch?._id === selectedBranch?.value)
    const promoCodeItems = code?.items?.map((item) => item?._id);
    const itemsResult = productIds?.every((id) => promoCodeItems?.includes(id));


    const result = isCurrentDateBetween(code?.startTime, code?.endTime);
    const userCount = code?.usedCustomerCount?.filter((count) => {
      return count?.userId == user._id
    })

    let discountedAmount = 0;

    if (code?.organizationId._id !== organizationId) {
      toast.error("This promo code is not applicable for this organization");
      setDiscountedAmount(0);
      return;
    }
    else if (!code?.status) {
      toast.error("This promo code is unavailable");
      setDiscountedAmount(0);
      return;
    }
    else if (result == false) {
      toast.error("Promo code is expired!");
      setPromoCodeData({})
      setDiscountedAmount(0);
      return;
    }
    else if (code?.usedCount >= code?.maxCount && code?.firstTimeUser == false && userCount[0]?.count >= 1) {
      toast.error("You reached maximum usage of this promo code");
      setPromoCodeData({})
      setDiscountedAmount(0);
      return;
    }
    else if (userCount[0]?.count >= code?.maxUserCount) {
      toast.error("You reached maximum usage of this promo code");
      setPromoCodeData({})
      setDiscountedAmount(0);
      return;
    }
    else if (code?.miniOrderValue > subTotalPrice) {
      toast.error("This promo code is not applicable for this order amount");
      setDiscountedAmount(0);
      return;
    }
    else if (code?.firstTimeUser == true && userCount[0]?.count >= 1) {
      toast.error("You already availed this promo code");
      setDiscountedAmount(0);
      return;
    }
    else if (!currentBranch) {
      toast.error("This promocode is not applicable for this branch");
      setDiscountedAmount(0);
      return;
    }
    else if (!itemsResult) {
      toast.error("This promocode is not applicable for these items");
      setDiscountedAmount(0);
      return;
    }


    if (code?.discountType === checkoutTypes.PERCENTAGE) {
      discountedAmount = (subTotalPrice * code.discount) / 100;
    }
    else if (code?.discountType === checkoutTypes?.FLAT) {
      discountedAmount = code.discount;
    }
    else {
      toast.error("Promo code is not valid");
      return;
    }

    if (discountedAmount > 0) {

      setDiscountedAmount(discountedAmount);
      setGrandTotal(subTotalPrice - discountedAmount);
      setPromoCodeData({ code: code.promoCode, user: user._id })
      toast.success("Promo code applied successfully");
    } else {
      setDiscountedAmount(0);
    }
  }, [promoCodes, cart]);


  const getCountryRegionInstance = () => {
    if (!countryRegion) {
      countryRegion = new CountryRegion()
    }
    return countryRegion
  }
  const handleCountryChange = event => setCountry(event)
  const handleStateChange = event => setState(event)
  const handleCityChange = event => setCity(event)
  const navigate = useNavigate();

  const inputHandle = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setFormData]
  );
  const inputPhoneHandle = useCallback(
    (value) => {
      if (value?.length <= 13) {
        setFormData((prevState) => ({
          ...prevState,
          phoneNum: value,
        }));
      }
    },
    [setFormData]
  );
  const organizationID = localStorage.getItem("organizationId")
  const [getAllOrders, { loading: orderLoading }] = useLazyQuery(GET_ALL_ORDERS, {
    onCompleted: resp => {
      setOrders(resp?.AllOrders)
      setItems(resp?.AllOrders?.map(order => order?.items))

    }
  })

  useEffect(() => {
    getAllOrders({
      variables: {
        orgId: organizationID,
        page: page,
        perPage: perPage,
        searchTerm: searchTerm,
        month: month,
        year: year,
      }
    })
  }, [month, year])

  const {
    loading: loadingProducts,
    error: errorProducts,
    data: dataProducts,
    execute: executeProducts,
  } = useCustomQuery(GET_PRODUCTS, {
    variables: {
      orgId: organizationID,
      page: page,
      perPage: perPage,
      searchTerm: searchTerm,
    },
  })

  const Products = useMemo(() => {
    if (!dataProducts?.Products?.products) return [];

    return dataProducts.Products.products.map((product) => ({
      id: product?._id,
      title: product?.title,
      price: product?.price,
      image: product?.image[0],
    }));
  }, [dataProducts]);


  const NameOfItems = useMemo(() => {
    const AllItemsCount = items?.map((item) => {
      return item?.map((i) => {
        return { id: i?.item?._id, count: 1 };
      });
    });

    const ItemsTotalCount = AllItemsCount?.reduce((acc, curr) => {
      curr?.forEach((item) => {
        const found = acc?.find((a) => a?.id === item?.id);
        if (found) {
          found.count = found?.count + item?.count;
        } else {
          acc?.push(item);
        }
      });
      return acc;
    }, []);

    const ItemsCounts = ItemsTotalCount?.sort((a, b) => b?.count - a?.count);

    const TrendingTop10ItemsCount = ItemsCounts?.slice(0, 10);

    return TrendingTop10ItemsCount?.map((item) => {
      const found = Products?.find((a) => a?.id === item?.id);
      if (found) {
        return { name: found?.title, count: item?.count, price: found?.price, image: found?.image };
      }
    });
  }, [items, Products]);
  const filteredArray = NameOfItems.filter(item => item !== undefined);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderId,
      paymentType,
      orderStatus: "PENDING",
      orderType: "DELIVERY",
      street: "",
      zip: "",
      ship_street: "",
      ship_zip: "",
      orderSource: isLiveOrder ? "LIVE_ORDER" : "PRE_ORDER",
      fullName: formData?.fullName,
      email: formData?.email,
      phoneNum: formData?.phoneNum,
    },

    onSubmit: values => {
      if (!values?.fullName) {
        toast.error("Please enter full name")
        return
      } else if (!values?.email) {
        toast.error("Please enter email")
        return
      } else if (!values?.phoneNum) {
        toast.error("Please enter phone number")
        return
      } else if (!formData?.street || !formData?.zip) {
        toast.error("Please enter personal address")
        return
      } else if (!country?.value) {
        toast.error("Please select country")
        return
      } else if (!state?.value) {
        toast.error("Please select state")
        return
      } else if (!city.value) {
        toast.error("Please select city")
        return
      } else if (!paymentType) {
        toast.error("Please select payment type")
        return
      }

      const input = {
        fullName: values?.fullName,
        email: values?.email,
        phoneNum: values?.phoneNum,
        orderId: values?.orderId,
        branchId: branchId,
        organizationId: orgData?.GetOrganizationByDomain[0]?._id,
        items: cart?.map(item => {
          return {
            item: item?._id,
            price: item?.totalPrice,
            quantity: item.quantity,
          }
        }),
        orderType: values?.orderType,
        customerAddress: `${formData?.street}, ${city?.label}, ${state?.label}, ${country?.label}, ${formData?.zip}`,
        orderShippingAddress: {
          city: { value: ship_city?.value, label: ship_city?.label },
          state: { value: ship_state?.value, label: ship_state?.label },
          country: { value: ship_country?.value, label: ship_country?.label },
          zip: formData?.ship_zip,
          street: formData?.ship_street,
        },
        deliveryDate: dateTime,
        deliveryCharges,
        discountedAmount: parseFloat(discountedAmount),
        totalAmount: (parseFloat(deliveryCharges) + parseFloat(grandTotal)),
        status: values?.orderStatus,
        paymentType: values?.paymentType,
        orderSource: values?.orderSource,
        latitude: `${locationCord.latitude}`,
        longitude: `${locationCord.longitude}`
      }

      if (promoCodeData?.code) {
        UpdatePromoCodeCount("UpdatePromoCodeCount", {
          promoCode: promoCodeData?.code,
          userId: promoCodeData?.user
        })
      }

      CreateLiveOrder("CreateLiveOrder", { input })
        .then(order => {
          if (order) {
            dispatch(clearCart())
            toast.success("Order Placed Successfully!")
            setModalShow(false)
            setmodal_center(true)
            navigate(`/order-track/${order?._id}`)
            socket.emit("client:send_notification", {
              org: organizationNameId,
              content: "Live-Order Placed Successfully!",
            })
          }
        })
        .catch(err => {
          toast.error(err)
        })
    },
  })

  const [addLoyaltyPoints] = useMutation(ADD_LOYALTY_POINTS)
  const [updateLoyaltyPoints] = useMutation(UPDATE_LOYALTY_POINTS)

  const [getAllLoyaltyPercentage, { loading: getAllPointsLoading, _, loyaltyPercentageData }] =
    useLazyQuery(GET_LOYALTY_PERCENTAGE, {
      onCompleted: data => {
        if (data?.Percentage?.status) {
          const percentStatus = data?.Percentage?.status;
          if (percentStatus) {
            setLoyaltyPercentage(data?.Percentage?.percentage)
          }
        }
      },
    })

  const [getAllLoyaltyPoints, { loading: getAllPercentageLoading, __, loyaltyPointsData }] =
    useLazyQuery(GET_LOYALTY_POINTS_BY_CUSTOMER, {
      onCompleted: data => {
        if (data.LoyaltyPoint.loyaltyPoints.length > 0 && data?.LoyaltyPoint?.loyaltyPoints[0]?.points) {
          setLoyaltyPoints(data?.LoyaltyPoint?.loyaltyPoints[0]?.points || 0)
        }
      },
    })

  useEffect(() => {
    user && getAllLoyaltyPoints({
      variables: {
        loyaltyPointId: user?._id,
        page: 1,
        perPage: 10,
      }
    })
    user && getAllLoyaltyPercentage()
  }, [])


  const checkoutHandle = async e => {
    e.preventDefault()
    if (user && loyaltyPointCheck) {
      const variables = {
        updateLoyaltyPointsId: user._id,
        input: {
          customerId: user._id,
          points: parseFloat(totalAmount),
          type: loyaltyPointsType.DEBIT,
          orderId: orderId,
        }
      };

      updateLoyaltyPoints({
        variables: variables
      })
        .then((response) => {
          toast.success("You used your loyalty points in this purchase!")
        })
        .catch((error) => {
          console.error('Error adding loyalty points:', error);
        });

    } else if (user && !loyaltyPointCheck) {
      const variables = {
        input: {
          purchaseAmount: parseFloat(totalAmount),
          organizationId: organizationId,
          type: loyaltyPointsType.CREDIT,
          customerId: user._id,
          orderId: orderId,
        },
        percent: loyaltyPercentage,
      };
      addLoyaltyPoints({
        variables: variables
      })
        .then((response) => {
          toast.success("Loyalty points add successfully!")
        })
        .catch((error) => {
          console.error('Error adding loyalty points:', error);
        });
    }
    const data = {
      fullName: formData.fullName,
      email: formData?.email,
      phoneNum: formData.phoneNum,
      zip: formData?.zip,
      street: formData?.street,
      city: city,
      state: state,
      country: country,
      ship_zip: formData?.ship_zip,
      ship_street: formData?.ship_street,
      ship_city: ship_city,
      ship_state: ship_state,
      ship_country: ship_country,
      paymentType: paymentType,
    }
    localStorage.setItem("shippingInfo", JSON.stringify(data))
    validation.handleSubmit()
    return null
  }

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await getCountryRegionInstance().getCountries()
        setCountries(
          countries.map(country => ({
            value: country.id,
            label: country.name,
          }))
        )
      } catch (error) {
        console.error(error)
      }
    }
    getCountries()
  }, [])

  useEffect(() => {
    const getStates = async () => {
      try {
        const states = await getCountryRegionInstance().getStates(country.value)
        setStates(
          states.map(userState => ({
            value: userState?.id,
            label: userState?.name,
          }))
        )
      } catch (error) {
        console.error(error)
      }
    }
    if (country) {
      getStates()
    }
  }, [country])

  useEffect(() => {
    const getLGAs = async () => {
      try {
        const lgas = await getCountryRegionInstance().getLGAs(
          country?.value,
          state?.value
        )
        setCities(
          lgas?.map(lga => ({
            value: lga?.id,
            label: lga?.name,
          }))
        )
      } catch (error) {
        console.error(error)
      }
    }
    if (state) {
      getLGAs()
    }
  }, [country, state])

  useEffect(() => {
    if (orgData) {
      localStorage.setItem(
        "organizationName",
        orgData?.GetOrganizationByDomain[0]?.name
      )
      localStorage.setItem(
        "organizationId",
        orgData?.GetOrganizationByDomain[0]?._id
      )
      setOrganizationName(orgData?.GetOrganizationByDomain[0]?.name)
    }
  }, [orgData])

  var { loading, error, data, execute } = useCustomQuery(
    GET_CONFIGURATIONS_OF_ORG_NAME,
    {
      variables: { orgName: subdomain },
    }
  )

  useEffect(() => {
    const elem = document.getElementById("main-content")
    elem.style.overflow = "initial"

    return () => {
      elem.style.overflow = "hidden"
    }
  }, [])

  useEffect(() => {
    dispatch(getSubTotalPrice())
  }, [dispatch])

  useEffect(() => {
    const id = generateOrderId(10)
    setOrderId(id)
  }, [])

  useEffect(() => {
    if (data) {
      const siteConfigData = data?.GetSettingsOfOrganizationName
      sessionStorage.setItem("siteSettings", JSON.stringify(siteConfigData[0]))
      setTheme(siteConfigData[0]?.theme)
    }
  }, [data])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocationCord({ latitude: position.coords.latitude, longitude: position.coords.longitude });
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setmodal_center(false)
    }, 3000)
  }, [modal_center])
  function tog_center() {
    setmodal_center(!modal_center);
  }


  var settings = {
    autoplay: true,
    dots: false,
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 800,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const { loading: getAllBranchesLoading, data: getAllBranchesData } = useCustomQuery(
    GET_BRANCHES_BY_ORG_NAME,
    {
      variables: {
        orgName: subdomain,
      },
    }
  )

  useEffect(() => {

    if (getAllBranchesData) {
      setBranches(
        getAllBranchesData?.GetAllActiveBrachesByOrgName
      )
    }

  }, [getAllBranchesData])



  const getBranchTitle = JSON.parse(localStorage.getItem("selectedBranch"))

  const filterBranchData = branches.filter((branch) => getBranchTitle.label == branch.title)

  const getBranchTitleAndId = JSON.parse(localStorage.getItem("selectedBranch"))
  const { loading: getBranchTimingsLoading, data: getBranchTimingsData } = useCustomQuery(
    GET_BRANCH_TIMINGS_BY_BRANCH_ID,
    {
      variables: {
        getBranchSettingsByBranchIdId: getBranchTitleAndId.value,
      },
    }
  )

  useEffect(() => {

    if (getBranchTimingsData) {

      setBranchTimings(getBranchTimingsData?.GetBranchSettingsByBranchId)
    }
  }, [getBranchTimingsData, fetchProducts])



  const isBranchOpen = (dateTime) => {
    const currentDay = dateTime
      ? dateTime.toLocaleDateString('en-US', { weekday: 'long' })
      : new Date().toLocaleDateString('en-US', { weekday: 'long' });

    const currentTime = dateTime
      ? dateTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
      : new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

    const branchSchedular = filterBranchData && filterBranchData[0]?.scheduler
    const globalTiming = branchTimings?.globalTiming?.find(timing => timing.day === currentDay);
    const schedulerTiming = branchSchedular?.find(timing => timing.date === formatDate(new Date()));

    const globalFirstHalfOpenTime = new Date(`2000-01-01T${globalTiming?.firstHalfOpen}:00Z`);
    const globalFirstHalfCloseTime = new Date(`2000-01-01T${globalTiming?.firstHalfClose}:00Z`);
    const globalSecondHalfOpenTime = new Date(`2000-01-01T${globalTiming?.secondHalfOpen}:00Z`);
    const globalSecondHalfCloseTime = new Date(`2000-01-01T${globalTiming?.secondHalfClose}:00Z`);
    const schedulerFirstHalfOpenTime = new Date(`2000-01-01T${schedulerTiming?.firstHalfOpen}:00Z`);
    const schedulerFirstHalfCloseTime = new Date(`2000-01-01T${schedulerTiming?.firstHalfClose}:00Z`);
    const schedulersSecondHalfOpenTime = new Date(`2000-01-01T${schedulerTiming?.secondHalfOpen}:00Z`);
    const schedulerSecondHalfCloseTime = new Date(`2000-01-01T${schedulerTiming?.secondHalfClose}:00Z`);
    const currentDateTime = new Date(`2000-01-01T${currentTime}:00Z`);

    if (schedulerTiming && schedulerTiming?.status) {
      // Check if the current time falls within the scheduler timing
      if ((currentDateTime >= schedulerFirstHalfOpenTime && currentDateTime <= schedulerFirstHalfCloseTime) ||
        (currentDateTime >= schedulersSecondHalfOpenTime && currentDateTime <= schedulerSecondHalfCloseTime)) {
        return true;
      }
      return false
    }
    if (globalTiming || schedulerTiming) {
      // Check if the current time falls within the global timing
      if ((currentDateTime >= globalFirstHalfOpenTime && currentDateTime <= globalFirstHalfCloseTime) ||
        (currentDateTime >= globalSecondHalfOpenTime && currentDateTime <= globalSecondHalfCloseTime)) {
        return true;
      }
      return false;
    }
    return false; // No timing found for the current day
  };

  // Function to format the date in 'YYYY-MM-DD' format
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  //branch timings
  const getBranchTiming = () => {
    const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });
    const todayTiming = branchTimings?.globalTiming?.find(timing => timing.day === currentDay);

    if (todayTiming) {
      const currentTime = new Date();
      const [hours, minutes] = todayTiming?.firstHalfOpen.split(':');

      // Set the current time to the specified opening time
      currentTime.setHours(Number(hours));
      currentTime.setMinutes(Number(minutes));

      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const openingTime = currentTime.toLocaleTimeString('en-US', options);

      return {
        openingTime,
      };
    } else {
      return null; // No timing found for the current day
    }
  };



  useEffect(() => {
    if (fetchProducts == true) {

      const branchTiming = getBranchTiming();
      setBranchOpenTime(branchTiming?.openingTime)

    }
  }, [branchTimings, fetchProducts, getBranchTimingsData])

  const handleDateChange = (date) => {
    const today = new Date()
    const selectedDate = new Date(date[0])
    selectedDate.setDate(selectedDate.getDate())
    setDateTime(selectedDate)

    const branchSchedular = filterBranchData && filterBranchData[0]?.scheduler
    const schedulerTiming = branchSchedular?.find(timing => timing.date === formatDate(new Date()));

    if ((today.toDateString() === selectedDate.toDateString()) && schedulerTiming && !schedulerTiming?.status) {
      setIsLiveOrder(false)
      setCheckoutEnable(false)
      toast.info("Branch is closed today")
      return
    } else if (formatDate(today) < formatDate(selectedDate)) {
      setCheckoutEnable(true)
      setIsLiveOrder(false)
      return
    } else if (today.getDate() > selectedDate.getDate()) {
      setCheckoutEnable(false)
      return
    }

    if (isBranchOpen(selectedDate)) {
      setCheckoutEnable(true)
      setIsLiveOrder(true)
    } else {
      setIsLiveOrder(false)
      setCheckoutEnable(false)
      toast.info("Branch is closed")
    }

  }


  return (
    <React.Fragment>
      <Modal
        show={modal_center}
        onHide={() => setmodal_center(false)}
        centered
      >
        <div className="custom-modal thank-modal">
          <div className="icon-top"><i className="fa fa-check"></i></div>
          <div className="content">
            <p className="type">Thank You for Purchasing</p>
            <p className="message-type">Your Order Placed Succesfully!</p>
          </div>
        </div>
      </Modal>
      <div className="cart-page">
        <Container>
          <CardBody className="py-5">
            <Row>
              {!branchOpen && (
                <span className="px-2 fw-bold font-size-11 text-capitalize color-red mb-3 branchClose-text"><i className="color-red">Sorry! The branch is currently closed. Branch Opening timings are {branchOpenTime}</i></span>
              )}
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-3">Delivery Information</h5>
                    <Card className="p-4">
                      <Form onSubmit={checkoutHandle}>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="full_name">
                                Full Name
                              </Label>
                              <Input
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={inputHandle}
                                placeholder="Full Name"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="phone_number">
                                Phone Number
                              </Label>
                              <PhoneInput
                                name="phoneNum"
                                className={`${formData?.phoneNum ? (isValidPhoneNumber(formData?.phoneNum) ? undefined : 'invalid') : 'invalid'}`}
                                placeholder="Enter Phone Number"
                                value={formData?.phoneNum}
                                onChange={inputPhoneHandle}
                                countryCallingCodeEditable={false}
                                defaultCountry="PK"
                                international
                                limitMaxLength
                                required
                              />
                              <span style={{ marginLeft: '2rem', color: 'red' }}>{formData?.phoneNum ? (isValidPhoneNumber(formData?.phoneNum) ? undefined : 'Invalid phone number') : 'Phone number required'}</span>
                              <br />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="phone_number">
                                Email Address
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={inputHandle}
                                placeholder="Email"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="phone_number">
                                Country
                              </Label>
                              <Select
                                type="text"
                                styles={colourStyles}
                                placeholder="Country"
                                id="country"
                                onChange={handleCountryChange}
                                options={countries}
                                value={country}
                                className="submain-one-form-body-subsection-select"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={5}>
                            <FormGroup>
                              <Label for="phone_number">
                                State
                              </Label>
                              <Select
                                type="text"
                                styles={colourStyles}
                                placeholder="State"
                                id="states"
                                onChange={handleStateChange}
                                options={states}
                                value={state}
                                className="submain-one-form-body-subsection-select"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label for="phone_number">
                                City
                              </Label>
                              <Select
                                type="text"
                                styles={colourStyles}
                                placeholder="City"
                                id="city"
                                onChange={handleCityChange}
                                options={cities}
                                value={city}
                                className="submain-one-form-body-subsection-select"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="phone_number">
                                Zip Code
                              </Label>
                              <Input
                                type="text"
                                name="zip"
                                value={formData.zip}
                                onChange={inputHandle}
                                placeholder="Zip Code"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="phone_number">
                                Address
                              </Label>
                              <Input
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={inputHandle}
                                placeholder="Street"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="phone_number">
                                Select Date time
                              </Label>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Select date time"
                                options={{
                                  dateFormat: "Y-m-d h:i K",
                                  enableTime: true,
                                  time_12hr: true,
                                  minDate: "today",
                                }}
                                value={dateTime}
                                onChange={(date) => handleDateChange(date)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-3">Payment Method</h5>
                    <Card className="p-4">
                      <Form>
                        <div className="d-flex flex-column">
                          <Row>
                            <Col md={6}>
                              <div className="form-check d-flex align-items-center justify-content-start">
                                <input className="form-check-input me-2 my-0" onClick={() => handleOptionClick('cash_on_delivery')} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label">
                                  Cash on Delivery
                                </label>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-check d-flex align-items-center justify-content-start">
                                <input className="form-check-input me-2 my-0" onClick={() => handleOptionClick('card_payment')} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label">
                                  Credit/Debit Card
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>

                      </Form>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col md={4}>
                <h5 className="mb-3">Loyalty</h5>
                <Card className="">
                  {/* // Use Loyalty Points */}
                  <div className="d-flex flex-column">
                    <span className="text-dark mb-3 fs-4">Use Loyalty Points</span>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="text-black-50 fs-4 pb-2">{loyaltyPoints > 0 ? loyaltyPoints : 0} Points</span>
                      <div className="rounded-switch">
                        <input
                          type="checkbox"
                          id="loyaltyPointCheck"
                          className="switch"
                          checked={loyaltyPointCheck}
                          disabled={loyaltyPoints <= totalAmount}
                          onInput={e => {
                            setloyaltyPointCheck(!e.target.checked)
                          }}

                        />
                        <label htmlFor="loyaltyPointCheck" />
                      </div>
                    </div>
                  </div>
                </Card>
                <h5 className="mb-3">Order Summary</h5>
                <Card className={`p-0 ${cart.length === 0 ? "" : "h-auto"}`}>
                  <CardBody className="">
                    <h5 className="mb-3">{cart?.length} Item{cart?.length > 1 && "s"} Added</h5>
                    {cart?.length === 0 ? (
                      <div className="h-auto d-flex justify-content-center align-items-center">
                        <h4 className="text-center">
                          <img src={cart_empty} className="w-25 mb-3" />
                          <br />
                          <div>You haven't added any items in cart yet</div>
                          <Link to="/items" className="mt-3">
                            <div className="mt-3 btn page-btn">
                              <i className="mdi mdi-arrow-left-bold-circle"></i> Go Back
                            </div>
                          </Link>
                        </h4>
                      </div>
                    ) : (
                      <Row>
                        <Col md={12}>
                          <Cart cart={cart} />
                          <div className="border-top py-2">
                            <h5>Items You May Like</h5>
                            <Slider {...settings} className="card-slider cart-slider px-0">
                              {filteredArray?.map(item => (
                                <div key={item?._id} className="product px-0">
                                  <Card2 trendingItems={true} title={item?.name} image={[item?.image]} price={item?.price} />
                                </div>
                              ))}


                            </Slider>
                          </div>
                          <div className="border-top">
                            <div className="cart-sidebar-head">
                            </div>
                            <div className="cart-sidebar-body" style={{ fontSize: 16 }}>
                              {!user ? (
                                <div className="d-flex flex-column">
                                  <small className="my-2 d-flex justify-content-center py-1 bg-light">Please login to avail promo code</small>
                                </div>
                              ) : (
                                <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Alert color="primary" className="text-muted fs-6 my-2 p-0 w-100 rounded-0 px-2">
                                      Enter Promo code if you have
                                    </Alert>
                                  </div>
                                  <Input
                                    id="promo_code"
                                    className="my-2 rounded rounded-pill"
                                    name="promo_code"
                                    placeholder="Promo code"
                                    type="text"
                                    value={promoCode}
                                    onChange={handlePromoCodeChange}
                                  />
                                </div>
                              )}
                              <div>
                                <div className="border-top subtotal-section d-flex justify-content-between align-items-center py-1">
                                  <span className="text-dark">Subtotal</span>
                                  <span className="text-muted">{CURRENCY_SYMBOL} {floatValue(subTotalPrice)}</span>
                                </div>
                                <div className="d-flex subtotal-section justify-content-between align-items-center py-1">
                                  <span className="text-dark">Delivery Charges</span>
                                  <span className="text-muted">{CURRENCY_SYMBOL} {deliveryCharges}</span>
                                </div>

                                {!user || discountedAmount === 0 ? (
                                  <div className="d-flex flex-column">
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-dark">promo code</span>
                                    <span className="text-muted">{CURRENCY_SYMBOL} -{floatValue(discountedAmount)}</span>
                                  </div>
                                )}
                                {!user || !loyaltyPointCheck ? (
                                  <div className="d-flex flex-column">
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-dark">Loyalty Discount</span>
                                    <span className="text-muted">{totalAmount}</span>
                                  </div>
                                )}
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <span className="fw-bold text-dark">Grand total</span>
                                  <span className="text-muted">{CURRENCY_SYMBOL} {totalAmount}</span>
                                </div>
                              </div>

                            </div>
                            <Form onSubmit={checkoutHandle}>
                              <div className="cart-sidebar-footer">
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                  <div className="w-100 mt-2">
                                    <button disabled={!checkoutEnable} className={`w-100 fw-bold ${checkoutEnable ? "page-btn" : "disablePageButton"}`}>
                                      Confirm Order
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Items)
