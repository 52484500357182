import {
  GET_BRANCH_PRODUCTS, IS_BRANCH_CHANGE,
  
} from "./actionTypes"

const INIT_STATE = {
  fetchProducts: false,
  isChange: false,
}

const GetBranchProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCH_PRODUCTS:
      return {
        ...state,
        fetchProducts: action.payload,
      }
    case IS_BRANCH_CHANGE:
      return {
        ...state,
        isChange: action.payload,
      }  
    
    default:
      return state
  }
}

export default GetBranchProducts
