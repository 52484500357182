import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import Modal from 'react-bootstrap/Modal';
import { Player } from '@lottiefiles/react-lottie-player';
import { CURRENCY_SYMBOL } from "constants/modules"
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { GET_ORDER } from 'graphql/queries/order';
import { useLocation, useParams } from 'react-router-dom';
import { ORDER_PAYMENT_TYPE } from 'utils/constants';

const OrderTracking = () => {
    const { id } = useParams()
    const location = useLocation()
    const [modalShow, setModalShow] = useState(false);
    const { cart, subTotalPrice } = useSelector(state => state.Cart)
    const [grandTotal, setGrandTotal] = useState(subTotalPrice);
    const [deliveryCharges, setDeliveryCharges] = useState(100);
    const [orderData, setOrderData] = useState()

    const [getOrder] = useLazyQuery(GET_ORDER, {
        onCompleted: data => {
            console.log(data?.Invoice)
            setOrderData(data?.Invoice)
        }
    })

    useEffect(() => {
        setModalShow(true);
    },[])
    const handleClose = () => {
        setModalShow(false);
    }
    useEffect(()=>{
        if(id) {
            getOrder({
                variables:{
                    invoiceId: id
                }
            })
        }
    },[id, location])

    return (
    <React.Fragment>
        <Modal
            show={modalShow}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <Player
                    autoplay
                    loop
                    src="https://lottie.host/7e9337bb-6f69-4c29-892c-d48c0259c212/i5CnvugAUI.json"
                    style={{ height: '100px', width: '100px' }}
                    >
                </Player>
                <h4>Hey! Register to earn loyalty points</h4>
                <p>
                    As Registered member, you'll be able to earn and redeem loyalty points on every order.
                </p>
                <Button className='page-btn'>Register Now</Button>
            </Modal.Body>
        </Modal>
        <Container className='my-5'>
            <Row>
                <Col md={7}>
                    <h5>Track Order</h5>
                    <Card>
                        <CardBody>
                            <div className='text-center'>
                                <p className='mb-0'>Your order status is <span style={{textTransform:"lowercase"}}>{orderData?.status}</span></p>
                                <h2>{orderData?.status}</h2>
                                <Player
                                    autoplay
                                    loop
                                    src="https://lottie.host/9975abff-3cc2-440f-919e-f3aa8f92d91c/wUuXA0Esq4.json"
                                    style={{ height: '200px', width: '200px' }}
                                    >
                                </Player>
                                <p>
                                    Your Order has been received. You will get a confirmation shortly once your
                                    order is accepted. Thank you!
                                </p>
                                <h4>
                                    Order Number: <b style={{textTransform:'uppercase'}}>{orderData?.orderId}</b>
                                </h4>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={5}>
                    <h5>Order Details</h5>
                    <Card>
                        <CardBody>
                            <div className='border-bottom'>
                                <p>
                                    Delivery Address: {orderData?.customerAddress}
                                </p>
                                <h6>
                                    Order Date: {new Date(parseInt(orderData?.createdAt)).toLocaleString()}
                                </h6>
                            </div>
                            <div>
                                <div className='py-2 border-bottom'>
                                    <h5>Items</h5>
                                        {
                                            orderData?.items?.map(
                                                item => (
                                                    <Row key={item?._id} className="cart-items-cont pb-2">
                                                        <Col md={12} className="d-flex align-items-center justify-content-between cart-item-desc">
                                                            <div className="cart-item-name">{item?.quantity} X {item?.item?.title}</div>
                                                            <div className=" cart-item-price">
                                                                Rs {item?.price * item?.quantity}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            )
                                        }
                                </div>
                                <div className='py-2 border-bottom'>
                                    <Row>
                                        <div className='d-flex align-items-center justify-content-between pb-2'>
                                            <div>
                                                Subtotal
                                            </div>
                                            <div>
                                                Rs {(parseFloat(orderData?.totalAmount) - parseFloat(orderData?.deliveryCharges)).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between pb-2'>
                                            <div>
                                                Delivery Charges
                                            </div>
                                            <div>
                                                {CURRENCY_SYMBOL} {orderData?.deliveryCharges}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between fw-bold'>
                                            <div>
                                                Total Amount
                                            </div>
                                            <div>
                                                {CURRENCY_SYMBOL} {(parseFloat(orderData?.totalAmount)).toFixed(2)}
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className='py-2'>
                                    <h5>Payment Method</h5>
                                    <div>{ORDER_PAYMENT_TYPE[orderData?.paymentType]}</div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
  )
}

export default OrderTracking