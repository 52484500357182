import {
  GET_BRANCH_PRODUCTS, IS_BRANCH_CHANGE,

} from "./actionTypes"

export const getBranchProducts = (isTrue) => ({
  type: GET_BRANCH_PRODUCTS,
  payload: isTrue,
})

export const branchChange = (isTrue) => ({
  type: IS_BRANCH_CHANGE,
  payload: isTrue,
})