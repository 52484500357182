import { gql } from "graphql-tag";

const GET_FOOTER_LINKS_SETTINGS_BY_ORG_ID = gql`
query GetFooterLinksSettingsByOrganizationId($organizationId: ID, $branchId: ID) {
    GetFooterLinksSettingsByOrganizationId(organizationId: $organizationId, branchId: $branchId) {
      _id
      playStoreLink
      appStoreLink
      instagramLink
      facebookLink
      linkedinLink
      twitterLink
      orgEmail
      orgPhone
      startTime
      endTime
      address
      branchEmail
      branchPhone
      branchAddress
      youtubeLink
      organizationId
      createdAt
      updatedAt
    }
  }
`;



export {
    GET_FOOTER_LINKS_SETTINGS_BY_ORG_ID,
};
