import React, { useEffect, useState } from "react"
import { GET_FAVORITE_PRODUCTS, GET_PRODUCTS_OF_BRANCH_PAGINATION } from "../../graphql/queries/product"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import { Card, CardBody, CardHeader, Row, Col, Button, Container, Table, Badge } from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { Image } from "react-bootstrap"
import { CREATE_FAVORITE_PRODUCTS_MUTATION, UPDATE_FAVORITE_PRODUCTS_MUTATION } from "graphql/mutations/product"
import useCustomMutation from "graphql/hooks/useCustomMutation"
import { use } from "i18next"
import PuffLoading from "components/Loading/PuffLoading"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom";


const Favorite = props => {
    document.title = "Favorite Products"
    const orgId = localStorage.getItem("organizationId")
    const user = JSON.parse(localStorage.getItem("authUser"))
    const branchId = localStorage.getItem("branchId")
    const [productList, setProductList] = React.useState([])
    const orgName = localStorage.getItem("organizationName")
    const [isBranch, setIsBranch] = React.useState(false)
    const [activeClass, setActiveClass] = useState("")

    const [getFavoriteProducts, { loading: favoriteProductsLoading, error: favoriteProductsError, data: favoriteProducts }] = useLazyQuery(GET_FAVORITE_PRODUCTS, {
        onCompleted: data => {
            if (data?.GetFavoriteProducts?.length == 0) {
                toast.error("You don't have any favorite products")
            }
        },
    })

    useEffect(() => {
        getFavoriteProducts({
            variables: {
                userId: user?._id,
                orgId: orgId,
            },
        })
    }, [])

    const favList = favoriteProducts?.GetFavoriteProducts

    const [getProductsOfbranch, { loading: getAllProducts, ____, productsData }] =
        useLazyQuery(GET_PRODUCTS_OF_BRANCH_PAGINATION, {
            onCompleted: data => {
                if (data?.GetProductsOfBranchPagination.products.length == 0) {
                    setIsBranch(false)
                } else {
                    setIsBranch(true)
                    setProductList(data.GetProductsOfBranchPagination.products)
                }
            },
        })

    useEffect(() => {
        getProductsOfbranch({
            variables: {
                organizationId: orgId,
                getProductsOfBranchPaginationId: branchId,
                limit: 10,
                skip: 0,
                page: 1,
                perPage: 1000000,
                isTrash: false,
                search: "",
                orgName: orgName,
            },
        })
    }, [branchId])

    const favProductList = favList?.map(favoriteProduct => {
        const product = productList?.find(product => product._id === favoriteProduct.productId)
        return product
    })

    const showImage = product => {
        if (product?.image?.length > 0) {
            const imageUrl = `${process.env.REACT_APP_APOLLO_SERVER_URL}/${orgName}/${product?.image[0]?.moduleType}/${product?.image[0]?.image}`;
            return imageUrl;
        } else {
            return product?.image[0]?.image;
        }
    };

    useEffect(() => {
        if (user) {
            const favList = favoriteProducts?.GetFavoriteProducts;
            if (favList?.length > 0) {
                favList.map(item => {
                    jQuery(`.product-${item.productId.slice(-5)}`).addClass("active")
                })
            }
        }
    }, [favoriteProducts, user]);

    const [CreateFavoriteProduct, { loading: createFavoriteProductLoading }] =
        useMutation(CREATE_FAVORITE_PRODUCTS_MUTATION)

    const [UpdateFavoriteProduct, { loading: updateFavoriteProductLoading }] =
        useMutation(UPDATE_FAVORITE_PRODUCTS_MUTATION, 
            {
                refetchQueries: [{
                    query: GET_FAVORITE_PRODUCTS,
                    variables: {
                        userId: user?._id,
                        orgId: orgId,
                    },
                }],
            })

    useEffect(() => {
        if (!user) {
            setActiveClass("")
        }
    }, [user])

    const getProductId = async productId => {
        const activeClass = jQuery(`.product-${productId.slice(-5)}`).hasClass("active")
        if (!activeClass) {
            const formData = {
                productId: productId,
                createdBy: user._id,
                isFavorite: true,
                createdAt: new Date().toISOString(),
            }
            const result = await CreateFavoriteProduct({
                variables: {
                    input: formData,
                },
            })
            if (result) {
                toast.success("Added to favorite successfully")
                jQuery(`.product-${productId.slice(-5)}`).addClass("active")
            } else {
                toast.error("Something went wrong")
            }
        } else if (activeClass) {
            setActiveClass("")
            const result = UpdateFavoriteProduct({
                variables: {
                    updateFavoriteProductId: productId,
                },
            })
            if (result) {
                toast.success("Removed from favorite successfully")
            } else {
                toast.error("Something went wrong")
            }
        }
    }



    return (
        <React.Fragment>
            <div className="cart-page py-5">
                {getAllProducts && <PuffLoading />}
                <Container>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Favorites" breadcrumbItem="Favorite Products" />
                    <Row>
                        <Col lg="12">
                            <div >
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "100px" }}>
                                                    Images
                                                </th>
                                                <th scope="col" style={{ width: "2rem" }}>Items</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Tags</th>
                                                <th scope="col">Discounded Price</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {favProductList && favProductList.map(product => (
                                                <tr key={product?._id}>
                                                    <td>
                                                        <img
                                                            src={showImage(product)}
                                                            alt={product?.title}
                                                            className="avatar-sm rounded rouunded-pill"
                                                        />
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14">
                                                            <Link
                                                                className="text-dark"
                                                            >
                                                                {product?.title}
                                                            </Link>
                                                        </h5>
                                                        <p className="text-muted mb-0">
                                                            {product?.description.split(" ").splice(0, 10).join(" ")}...
                                                        </p>
                                                    </td>
                                                    <td> {product?.price}</td>
                                                    <td>
                                                        <Badge className={"bg-info fs-6"}>
                                                            {product?.tags ? product?.tags : "No Tags"}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge className={"bg-dark fs-6"}>
                                                            {product?.discountedPrice ? product?.discountedPrice : "No Discount"}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <div className="product-block">
                                                            <div className="product-transition">
                                                                <div
                                                                    onClick={() => {
                                                                        getProductId(product?._id)
                                                                    }}
                                                                    className={`${activeClass} ${user ? `product-${product?._id.slice(-5)}` : ''} wishlist favorite-icon bx bxs-heart`}
                                                                ></div>
                                                            </div>
                                                        </div>


                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default Favorite
