import { gql } from "graphql-tag";

const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    CreateProduct(input: $input) {
      _id
    }
  }
`;

const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProduct($updateProductId: ID!, $input: UpdateProductInput!) {
    UpdateProduct(id: $updateProductId, input: $input) {
      _id
    }
  }
`;

const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProduct($deleteProductId: ID!) {
    DeleteProduct(id: $deleteProductId) {
      _id
    }
  }
`;

const DELETE_PERMANENTLY_PRODUCT_MUTATION = gql`
  mutation DeleteProductPermanently($deleteProductPermanentlyId: ID!) {
    DeleteProductPermanently(id: $deleteProductPermanentlyId) {
      _id
    }
  }
`;

const RESTORE_PRODUCT_MUTATION = gql`
  mutation RestoreProduct($restoreProductId: ID!) {
    RestoreProduct(id: $restoreProductId) {
      _id
    }
  }
`;

const UPDATE_PRODUCT_STATUS = gql`
mutation UpdateProductToggleStatus($updateProductToggleStatusId: ID!, $input: updateProductStatusInput!) {
  UpdateProductToggleStatus(id: $updateProductToggleStatusId, input: $input) {
    _id
  }
}
`;

const BULK_PRODUCT_MUTATION = gql`
mutation BulkProduct($input: [bulkProductInput!]) {
  BulkProduct(input: $input) {
    _id
  }
}
`;

const CREATE_FAVORITE_PRODUCTS_MUTATION = gql`
mutation CreateFavoriteProduct($input: CreateFavoriteProductInput!) {
  CreateFavoriteProduct(input: $input) {
    _id
  }
}
`

const UPDATE_FAVORITE_PRODUCTS_MUTATION = gql`
mutation UpdateFavoriteProduct($updateFavoriteProductId: ID!) {
  UpdateFavoriteProduct(id: $updateFavoriteProductId) {
    _id
    productId
    organizationId {
      _id
    }
    createdAt
  }
}
`


export {
  CREATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
  DELETE_PERMANENTLY_PRODUCT_MUTATION,
  RESTORE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_STATUS,
  BULK_PRODUCT_MUTATION,
  CREATE_FAVORITE_PRODUCTS_MUTATION,
  UPDATE_FAVORITE_PRODUCTS_MUTATION
};
