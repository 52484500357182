import { gql } from "graphql-tag";

const GET_CONFIGURATIONS_OF_ORG_NAME = gql`
query GetSettingsOfOrganizationName($orgName: String!) {
    GetSettingsOfOrganizationName(orgName: $orgName) {
        _id
        host
        theme
        themeColors{
            name
            label
            value
        }
        headerColors{
            name
            label
            value
        }
        footerColors{
            name
            label
            value
        }
        menuColors{
            name
            label
            value
        }
        productColors{
            name
            label
            value
        }
    }
  }
`;



export {
    GET_CONFIGURATIONS_OF_ORG_NAME
};
