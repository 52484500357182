import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { connect, useSelector } from "react-redux"
import { Row, Col, Button, Form, Input, Container, FormText } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import Select from "react-select"
import { useLazyQuery } from "@apollo/client"
import {
  toggleCartSidebar,
} from "store/actions"
import { useDispatch } from "react-redux"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import TabPanel from "@mui/lab/TabPanel"
// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  Label,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  toggleLoginModal,
} from "../../store/actions"
import Topbar from "./Topbar"
import {
  GET_BRANCHES_BY_ORG_NAME,
  GET_HEADER_NOTICE,
} from "graphql/queries/branch"
import { branchChange, getBranchProducts } from "store/products/actions"
import {
  CREATE_CUSTOMER_REISTER,
  SIGNIN_CUSTOMER,
  SIGNIN_WITH_OTP,
} from "graphql/mutations/user"
import { AuthContext, UserAuth } from "context/AuthContext"
import { GET_FAVORITE_PRODUCTS } from "graphql/queries/product"
import useCustomQuery from "graphql/hooks/useCustomQuery"
// get image from assets
import otpLogo from "../../assets/images/two-step-verification.png"
import messageImage from "../../assets/images/messages.png"
import emailImage from "../../assets/images/envelope-mail.png"
import { CART_ICON, ME_ICON, PIN_ICON, SEARCH_ICON } from "utils/icons"

const Header = props => {
  const [colors, setColors] = useState()
  const [modalColors, setModalColors] = useState()
  const [modalShow, setModalShow] = React.useState(false)
  const [phoneModalShow, setPhoneModalShow] = React.useState(false)
  const [otpModalShow, setOTPModalShow] = React.useState(false)
  const [registerModalShow, setRegisterModalShow] = React.useState(false)
  const [branches, setBranches] = useState([])
  const [isBranch, setIsBranch] = useState(false)
  const [isTheme, setIsTheme] = useState('1')
  const [orderValue, setOrderValue] = React.useState("4")
  const [menu, setMenu] = useState(false)
  const [registerName, setRegisterName] = useState("")
  const [branch, setBranch] = useState(
    JSON.parse(localStorage.getItem("selectedBranch")) || {}
  )
  const dispatchRedux = useDispatch()
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [name, setName] = useState("")
  const [logout, setLogout] = useState(false)
  const { cart, showLoginModal } = useSelector(state => state.Cart)
  const { dispatch } = UserAuth()
  const { user } = useContext(AuthContext)
  const handleOrderChange = (event, newOrderValue) => {
    setOrderValue(newOrderValue)
  }

  const [headerNotice, setHeaderNotice] = useState("")
  const branchId = localStorage.getItem("branchId")

  var {
    loading,
    error,
    data: branchData,
    execute,
  } = useCustomQuery(GET_HEADER_NOTICE, {
    variables: { getHeaderNoticeId: branchId },
  })

  useEffect(() => {
    if (branchData) {
      setHeaderNotice(branchData?.GetHeaderNotice?.headerNotice)
    }
  }, [branchData])

  const [isExpanded, setIsExpanded] = useState(false)
  const handleSearchIconClick = () => {
    setIsExpanded(!isExpanded)
  }

  const headerColors = sessionStorage.getItem("siteSettings")
  const themeColors = sessionStorage.getItem("siteSettings")
  const theme = sessionStorage.getItem("siteSettings")
  useEffect(() => {
    if (headerColors == undefined) {
      setColors([])
    } else {
      setColors(JSON.parse(headerColors))
    }
  }, [headerColors])
  useEffect(() => {
    if (themeColors == undefined) {
      setModalColors([])
    } else {
      setModalColors(JSON.parse(themeColors))
    }
  }, [themeColors])

  const HeaderVariables = {
    Background:
      colors?.headerColors?.length > 0
        ? colors?.headerColors[0]?.value
        : "#fff",
    Text:
      colors?.headerColors?.length > 0
        ? colors?.headerColors[1]?.value
        : "#000000",
    Icon:
      colors?.headerColors?.length > 0
        ? colors?.headerColors[2]?.value
        : "#000000",
    CartCounterText:
      colors?.headerColors?.length > 0
        ? colors?.headerColors[4]?.value
        : "#ea7c69",
    CartCounter:
      colors?.headerColors?.length > 0
        ? colors?.headerColors[3]?.value
        : "#ea7c69",
  }
  const ThemeVariables = {
    Accent:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[0]?.value
        : "#FFF0E5",
    AccentText:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[1]?.value
        : "#000000",
    Button:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[2]?.value
        : "#ea7c69",
    ButtonText:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[3]?.value
        : "#ffffff",
    ButtonHover:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[4]?.value
        : "#ffffff",
    ButtonHoverText:
      modalColors?.themeColors?.length > 0
        ? modalColors?.themeColors[4]?.value
        : "#ea7c69",
  }
  const changeBranch = () => {
    setModalShow(true)
  }

  const handleClose = () => {
    setModalShow(false)
  }

  const handlePhoneClose = () => {
    dispatchRedux(toggleLoginModal(false))
  }

  const handleOtpClose = () => {
    setOTPModalShow(false)
  }

  const handleRegisterClose = () => {
    setRegisterModalShow(false)
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: styles => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const showBranch = () => {
    const ShowLocal = JSON.parse(localStorage.getItem("selectedBranch"))
    if (ShowLocal) {
      return ShowLocal.label
    }
    else {
      return "Select Branch"
    }
  }

  const [getAllBranches, { loading: getAllBraches, __, branchesData }] =
    useLazyQuery(GET_BRANCHES_BY_ORG_NAME, {
      onCompleted: data => {
        if (data?.GetAllActiveBrachesByOrgName.length == 0) {
          toast.error("No data found")
          setIsBranch(false)
        } else {
          setIsBranch(true)
          setBranches(
            data?.GetAllActiveBrachesByOrgName.map(branch => ({
              value: branch._id,
              label: branch.title,
            }))
          )
        }
      },
    })
  const subdomain = localStorage.getItem("orgDomain")
  const getBranches = () => {
    getAllBranches({
      variables: {
        orgName: subdomain,
      },
    })
  }




  const getItems = () => {
    setModalShow(false)
  }

  useEffect(() => {
    if (subdomain) {
      getBranches()
    }
  }, [subdomain])

  const handleSelectBranch = e => {
    setBranch(e)
  }

  const setBranchToLocal = () => {
    localStorage.setItem("selectedBranch", JSON.stringify(branch))
  }

  const openModal = () => {
    if (!user) {
      dispatchRedux(toggleLoginModal(true))
    }
  }

  var [SignIn, { loading, error }] = useMutation(SIGNIN_CUSTOMER)
  var [SignInWithOtp, { loading, error }] = useMutation(SIGNIN_WITH_OTP)
  var [CreateCustomerRegister, { loading, error }] = useMutation(
    CREATE_CUSTOMER_REISTER
  )

  const handleLogin = e => {
    e.preventDefault()
    const orgId = localStorage.getItem("organizationId")
    SignIn({ variables: { orgId: orgId, email: email, mobile: mobileNumber } })
      .then(res => {
        dispatchRedux(toggleLoginModal(false))
        if (res?.data?.SignInCustomer != null) {
          setOTPModalShow(true)
        } else {
          setRegisterModalShow(true)
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  const handleLoginWIthOtp = e => {
    e.preventDefault()
    SignInWithOtp({
      variables: { otp: otp, email: email, mobile: mobileNumber },
    })
      .then(user => {
        if (user) {
          if (user?.data?.SignInWithOTP != null) {
            setOTPModalShow(false)
            toast.success("Signin Successfully !")
            const userData = {
              ...user?.data?.SignInWithOTP?.user,
              authToken: user?.data?.SignInWithOTP?.token,
            }
            dispatch({ type: "LOGIN_SUCCESS", payload: userData })
          }
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  const orgId = localStorage.getItem("organizationId")

  const handleLogout = () => {
    localStorage.removeItem("authUser")
    dispatch({ type: "LOGOUT" })
    setLogout(true)
    window.location.href = "/"
  }

  const handleRegister = e => {
    e.preventDefault()
    const orgName = localStorage.getItem("organizationName")

    const formBody = {
      fullName: registerName,
      email: email,
      phoneNum: mobileNumber,
      fullName: registerName,
      byAdmin: false,
      organizationNameOrDomain: orgName,
    }

    CreateCustomerRegister({ variables: { input: formBody } })
      .then(user => {
        if (user) {
          if (user?.data?.CreateCustomerRegister != null) {
            setRegisterModalShow(false)
            toast.success(user?.data?.CreateCustomerRegister?.message)
            setOTPModalShow(true)
          }
        }
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  var [GetFavoriteProducts, { loading, error, data: favoriteProducts }] = useLazyQuery(GET_FAVORITE_PRODUCTS);

  useEffect(() => {
    if (user && orgId) {
      GetFavoriteProducts({
        variables: {
          userId: user?._id,
          orgId: orgId,
        },
      });
    }
  }, [user, orgId]);


  const count = favoriteProducts?.GetFavoriteProducts?.length
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={{
          "--header-bg": HeaderVariables.Background,
          "--header-text": HeaderVariables.Text,
          "--header-icon": HeaderVariables.Icon,
          "--header-counter": HeaderVariables.CartCounter,
          "--header-counter-text": HeaderVariables.CartCounterText,
        }}
      >
        {headerNotice ? <Topbar headerNotice={headerNotice} /> : null}

        <Container className={colors?.theme == '1' ? "navbar-header" : colors?.theme == '2' ? "header2" : 'header3'}>
          <div className="d-flex align-items-center">
            <img
              className="header-logo cursor-pointer"
              src="https://smartdemowp.com/snacky/wp-content/uploads/logo-light.png"
              onClick={() => {
                window.location.href = "/"
              }}
            />
            <div className={"branch-section " + (colors?.theme == '1' ? 'mx-5' : '')}>
              <div className="d-flex align-items-center select-branch">
                <span onClick={changeBranch} className="header-icon">{PIN_ICON}</span>
                <div className="d-flex flex-column px-1">
                  <span className="select-title">Deliver to</span>
                  <span className="location">{showBranch()}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-section">
            <img
                className="logo cursor-pointer"
                src="https://smartdemowp.com/snacky/wp-content/uploads/logo-light.png"
                onClick={() => {
                  window.location.href = "/"
                }}
              />
          </div>
          <Modal
            onHide={handleClose}
            show={modalShow}
            size="lg"
            className="login-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Select Branch
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className={"mb-4 align-items-center"}>
                <Col md={12}>
                  <Select
                    styles={colourStyles}
                    type="text"
                    placeholder="Select a branch"
                    id="name"
                    onChange={handleSelectBranch}
                    options={branches}
                    value={branch}
                    className="submain-one-form-body-subsection-select"
                  />
                </Col>
              </Row>
              <Button
                onClick={() => [
                  getItems(),
                  setBranchToLocal(),
                  dispatchRedux(getBranchProducts(true)),
                  dispatchRedux(branchChange(true)),
                ]}
                className="page-btn w-100"
              >
                Proceed
              </Button>
            </Modal.Body>
          </Modal>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center select-branch branch-icon">
              <img src={location} onClick={changeBranch} className="header-icon" />
              <div className="d-flex flex-column branch-title">
                <span className="select-title">Deliver to</span>
                <span className="location">{showBranch()}</span>
              </div>
            </div>
            {
              user ? (
                <Link
                  className="d-flex align-items-center cart-icon flex-column"
                  to='/favorites'
                >
                  <div className="position-relative">
                  {count > 0 ?
                    <Badge color="dark" pill>   
                    </Badge> : null}
                  </div>
                  <i className="mdi mdi-heart-multiple-outline header-icons" />
                </Link>
              ) : null
            }
            <div className={`search-container ${isExpanded ? "expanded" : ""}`}>
              <div
                className="search-icon noti-icon"
                onClick={handleSearchIconClick}
              >
                <span className="header-icon search-icon">{SEARCH_ICON}</span>
              </div>
              {isExpanded && (
                <div className="search-bar">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search me-2">
                      <Input
                        type="text"
                        className="form-control border-0"
                        placeholder="Search"
                      />
                    </div>
                  </Form>
                </div>
              )}
            </div>
            {/* <CartDropdown /> */}
            <a
              onClick={() => [
                dispatchRedux(toggleCartSidebar(true)),
              ]}
              className="d-flex align-items-center cart-icon flex-column"
            >
              <div className="position-relative">
              {cart?.length > 0 ?
                <Badge color="dark" pill>   
                </Badge> : null}
              </div>
              <span style={{marginTop: -7}} className="header-icon">{CART_ICON}</span>
            </a>
            <div onClick={openModal} className="noti-icon cart-icon">
              {user ? (
                <Dropdown
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="d-inline-block"
                >
                  <DropdownToggle
                    className="btn header-item d-flex align-items-center"
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                    <span className="header-icon">{ME_ICON}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to="/invoices" className="dropdown-item">
                      <i className="bx bx-food-menu font-size-16 align-middle me-1 text-danger" />
                      <span>{props.t("Order History")}</span>
                    </Link>
                    <a onClick={handleLogout} className="dropdown-item">
                      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                      <span>{props.t("Logout")}</span>
                    </a>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <span className="header-icon">{ME_ICON}</span>
              )}
            </div>

            <Modal
              onHide={handlePhoneClose}
              show={showLoginModal}
              size="lg"
              centered
              className="login-modal"
              style={{
                "--accent-bg": ThemeVariables.Accent,
                "--accent-text": ThemeVariables.AccentText,
                "--theme-button-bg": ThemeVariables.Button,
                "--theme-button-text": ThemeVariables.ButtonText,
                "--theme-button-hover-bg": ThemeVariables.ButtonHover,
                "--theme-button-hover-text": ThemeVariables.ButtonHoverText,
              }}
            >
              <Modal.Body>
                <TabContext value={orderValue}>
                  <TabList
                    className="nav nav-fill p-0 m-0"
                    onChange={handleOrderChange}
                  >
                    <Tab
                      className="nav-item nav-link fs-6"
                      label="Login with Phone"
                      value="4"
                    />
                    <Tab
                      className="nav-item nav-link fs-6"
                      label="Login with Email"
                      value="5"
                    />
                  </TabList>
                  <TabPanel value="4">
                    <Form>
                      <Row className="justify-content-center">
                        <Col md={12} className="p-0">
                          <Label
                            htmlFor="mobileNumber"
                            className="fs-4 fw-bold"
                          >
                            Enter your mobile number
                          </Label>
                          <br />
                          <FormText>
                            Please enter your country code with your mobile
                            number
                          </FormText>
                          <Input
                            type="text"
                            id="mobileNumber"
                            className="mt-2 form-control rounded rounded-pill"
                            placeholder="Enter mobile number"
                            value={mobileNumber}
                            onChange={e => setMobileNumber(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </TabPanel>
                  <TabPanel value="5">
                    <Form>
                      <Row className="justify-content-center">
                        <Col md={12} className="p-0">
                          <Label htmlFor="email" className="fs-4 fw-bold">
                            Enter your Email
                          </Label>
                          <br />
                          <FormText>
                            Please enter your valid email address
                          </FormText>
                          <Input
                            type="text"
                            id="email"
                            className="mt-2 form-control rounded rounded-pill"
                            placeholder="Enter your email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </TabPanel>
                  <Row className="justify-content-center mb-2 px-3">
                    <Col md={12} className="text-center mx-auto px-0">
                      <Button
                        color="primary"
                        className="w-100 login-btn"
                        onClick={handleLogin}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                  <span className="d-flex align-items-center mx-3">
                    <hr
                      className="flex-grow-1 my-0"
                      style={{ borderTop: "1px solid rgb(197, 197, 197)" }}
                    />
                    <span
                      className="px-2 text-muted"
                      style={{ fontSize: 12, color: "rgb(197, 197, 197)" }}
                    >
                      OR
                    </span>
                    <hr
                      className="flex-grow-1 my-0"
                      style={{ borderTop: "1px solid rgb(197, 197, 197)" }}
                    />
                  </span>

                  <Row className="justify-content-center mt-2 px-3">
                    <Col md={12} className="text-center mx-auto px-0">
                      <Link to="/checkout">
                        <Button
                          outline
                          className="w-100 modal-btn"
                          onClick={() => {
                            dispatchRedux(toggleLoginModal(false))
                          }}
                        >
                          Order as Guest
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </TabContext>
              </Modal.Body>
            </Modal>

            <Modal
              onHide={handleOtpClose}
              show={otpModalShow}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body closeButton>
                <div className="my-auto" id="contained-modal-title-vcenter">
                  <div className="text-center">
                    <div className="p-2 mt-4">
                      <h4>Verify your email/phone</h4>
                      <p>
                        Please enter the 6 digit code sent to your Email/Phone
                      </p>
                      <Form>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label htmlFor="digit1-input" className="visually-hidden">OTP</Label>
                              <Input type="text"
                                className="form-control form-control-lg text-center two-step"
                                value={otp}
                                onChange={e => setOtp(e.target.value)}
                                maxLength="6"
                                id="digit1-input" />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <div>
                        <Link
                          to="/"
                          className="btn page-btn w-100"
                          onClick={handleLoginWIthOtp}
                        >
                          Confirm
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              onHide={handleRegisterClose}
              show={registerModalShow}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body closeButton>
                <Form>
                  <Row className="justify-content-center">
                    <Col md={12} className="p-0">
                      <Label htmlFor="mobileNumber" className="fs-4 fw-bold">
                        Register your account
                      </Label>
                      <br />
                      <FormText>
                        Please enter your fullname email and your mobile number
                        <br />
                      </FormText>

                      <Label className="mt-2">Name</Label>
                      <Input
                        type="text"
                        className=" form-control rounded rounded-pill"
                        placeholder="Enter name!"
                        value={registerName}
                        onChange={e => setRegisterName(e.target.value)}
                      />
                      <Label className="mt-2">Email</Label>
                      <Input
                        type="email"
                        className=" form-control rounded rounded-pill"
                        placeholder="Enter email!"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      <Label className="mt-2">Mobile Number</Label>
                      <Input
                        type="text"
                        id="mobileNumber"
                        className=" form-control rounded rounded-pill"
                        placeholder="Enter mobile number"
                        value={mobileNumber}
                        onChange={e => setMobileNumber(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Form>
                <Row className="justify-content-center mb-2">
                  <Col md={12} className="text-center px-0 mt-4">
                    <Button
                      color="primary"
                      className="w-100 page-btn"
                      onClick={handleRegister}
                    >
                      Register
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
