import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { onError } from "@apollo/link-error"
import { toast } from "react-toastify"
import { createUploadLink } from 'apollo-upload-client';


// Create a custom link that adds an Authorization header to each request
const authLink = new ApolloLink((operation, forward) => {
  const user = localStorage.getItem("authUser")
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: user && user !== "null" ? `Bearer ${JSON.parse(user)?.authToken}` : "",
    },
  }))
  return forward(operation)
})

const SERVER_ERRORS = ["ACCOUNT_NOT_FOUND", "LOGIN_PLEASE", "TOKEN_EXPIRED"]

// Create a custom link that handles network errors and shows a toast message
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const errors = graphQLErrors?.map(error => {
      const messageArray = error?.message?.split(":")
      const message = messageArray[messageArray.length - 1]?.trim()
      return { message }
    })
    if (errors.length && SERVER_ERRORS.includes(errors[0].message)) {
      localStorage.setItem("authUser", null)
      window.location.href = "/"
    } else {
      errors.forEach(({ message }) => {
        // Show a toast message with the error message
        if (!message.startsWith("Response")) {
          toast.error(message)
        }
      })
    }
  }
  if (networkError) {
    // Show a toast message with the network error message
    // toast.error(networkError.message);
    toast.error(networkError.message)
  }
})

// Create an HTTP link that connects to the GraphQL API
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_SERVER_URL,
})

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_SERVER_URL,
});


// Create a client-side cache
const cache = new InMemoryCache()

// Create an Apollo Client instance
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink, httpLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV === "development",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
})

export default client
