import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_HEADER_NOTICE } from "graphql/queries/branch"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

const Topbar = ({ headerNotice }) => {
  return (
    <>
      <div className="text-center topbar-text">
        <marquee direction="left">{headerNotice ? headerNotice : ""}</marquee>
      </div>
    </>
  )
}

export default Topbar
