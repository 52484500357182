import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Label,
  Form,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { useLazyQuery } from "@apollo/client"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select"
import useCustomQuery from "graphql/hooks/useCustomQuery";
import { GET_BRANCHES_BY_ORG_NAME } from "graphql/queries/branch";
import { toast } from "react-toastify";
import { GET_CONFIGURATIONS, GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);
  const [isBranch, setIsBranch] = useState(false)
  const [orgName, setOrgName] = useState("")

  const [branch, setBranch] = useState({})
  const [branches, setBranches] = useState([])
  const navigate = useNavigate()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

 
  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      return parts[0];
    }
    return '';
  };

  const subdomain = getSubdomain();

  var { loading, error, data, execute } = useCustomQuery(GET_CONFIGURATIONS_OF_ORG_NAME, {
    variables: { orgName: subdomain },
  })

  useEffect(()=>{
    if(data){
      const siteConfigData = data?.GetSettingsOfOrganizationName
      sessionStorage.setItem("siteSettings", JSON.stringify(siteConfigData[0]))
    }
  }, [data])


  //meta title
  document.title = "Dashboard | Order placement";


  const [
    getAllBranches,
    { loading: getAllBraches, _, branchesData },
  ] = useLazyQuery(GET_BRANCHES_BY_ORG_NAME, {
    onCompleted: data => {
      if(data?.GetAllActiveBrachesByOrgName.length == 0){
        toast.error("No data found")
        setIsBranch(false)
      }else{
        setIsBranch(true)
        setBranches(
          data?.GetAllActiveBrachesByOrgName.map(branch => ({
            value: branch._id,
            label: branch.title,
          }))
        )
      }
    },
  })


  const getBranches = () => {

    getAllBranches({
      variables: {
        orgName: subdomain,
      },
    })
  }

  useEffect(()=> {
    if(subdomain){
      getBranches()
    }
  }, [subdomain])

  const getItems = () => {
    
  }

  const revertBranchField = ()=> {
    setIsBranch(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

        <Col lg={12}>
          <Card>
            <CardBody>
              <div >

              
              <h6 className="mb-4 card-title">Select Branch</h6>
              <Form
                className="outer-repeater"
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer col-md-6">
                     
                      
                        <Row className={
                            "mb-4 align-items-center" 
                          }>
                            
                            <Label
                            className="col-md-3"
                              style={
                                {
                                  minWidth: 120,
                                  marginTop: 6,
                                  paddingRight: 15,
                                }
                              }
                            >
                              Branch
                            </Label>
                            
                              <Select
                                type="text"
                                placeholder="Select a branch"
                                id="name"
                                onChange={(e)=>setBranch(e)}
                                options={branches}
                                value={branch}
                                className="submain-one-form-body-subsection-select col-md-9"
                              />
                        </Row>
                      
                      <Button onClick={getItems}>Proceed</Button>
                      </div>
                    </div>
                      </Form>
                      </div>
            </CardBody>
          </Card>
        </Col>

          
        </Container>
      </div>
    
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
