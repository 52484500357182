import { gql } from "graphql-tag"

const GET_LOYALTY_PERCENTAGE = gql`
query Percentage {
    Percentage {
        _id
      percentage
      organizationId {
        _id
      }
      status
      updatedAt
      updatedBy {
        _id
      }
      createdBy {
        _id
      }
      createdAt
    }
  }
`

export { GET_LOYALTY_PERCENTAGE }
