import { gql } from "graphql-tag"

const GET_PRODUCT = gql`
  query Product($productId: ID!) {
    Product(id: $productId) {
      _id
      title
      image {
        image
        moduleType
        reference
        _id
      }
      SKU
      price
      discountedPrice
      description
      priority
      tags
      toggleStatus
      branchId
      dealId
      createdAt
      updatedAt
      variations {
        _id
        branchId
        productId
        organizationId
        name
        multiCount
        quantityCount
        isMandatory
        isMultiSelect
        isQuantitySelect
        priority
        values {
          name
          priority
          price
          discount
          isActive {
            branchId
            isActive
          }
        }
      }
    }
  }
`

const GET_PRODUCTS_OF_BRANCH = gql`
  query GetProductsOfBranch($branchId: ID!) {
    GetProductsOfBranch(id: $branchId) {
      _id
      price
      title
      image
      toggleStatus
    }
  }
`

const GET_PRODUCTS = gql`
  query Products(
    $orgId: ID
    $page: Int!
    $perPage: Int!
    $isTrash: Boolean
    $search: String
  ) {
    Products(
      orgId: $orgId
      page: $page
      perPage: $perPage
      isTrash: $isTrash
      search: $search
    ) {
      products {
        _id
        title
        image {
          image
          moduleType
          reference
          _id
        }
        SKU
        price
        discountedPrice
        description
        priority
        tags
        toggleStatus
        branchId
        dealId
        createdAt
        updatedAt
        variations {
          _id
          branchId
          productId
          organizationId
          name
          multiCount
          quantityCount
          isMandatory
          isMultiSelect
          isQuantitySelect
          priority
          values {
            name
            priority
            price
            discount
            isActive {
              branchId
              isActive
            }
          }
        }
      }
      totalCount
    }
  }
`
const GET_LIVE_PRODUCTS = gql`
  query Query {
    GetLiveProducts {
      _id
      title
      image
      SKU
      price
      tags
      deletedAt
      branchId
      dealId
      toggleStatus
      quantity
      createdAt
      updatedAt
    }
  }
`

const GET_TRASH_PRODUCTS = gql`
  query GetTrashProducts($page: Int!, $perPage: Int!) {
    GetTrashProducts(page: $page, perPage: $perPage) {
      products {
        _id
        title
        image
        SKU
        price
        tags
        branchId
        dealId
        createdBy {
          _id
        }
        deletedAt
        deletedBy {
          _id
        }
        updatedAt
      }
      totalCount
    }
  }
`

const GET_TRASH_PRODUCTS_COUNT = gql`
  query Product {
    GetTrashProductsCount {
      count
    }
  }
`

const GET_PRODUCTS_OF_BRANCH_PAGINATION = gql`
  query GetProductsOfBranchPagination(
    $page: Int!
    $getProductsOfBranchPaginationId: ID!
    $orgName: String!
    $isTrash: Boolean
    $perPage: Int!
    $search: String
  ) {
    GetProductsOfBranchPagination(
      page: $page
      id: $getProductsOfBranchPaginationId
      orgName: $orgName
      isTrash: $isTrash
      perPage: $perPage
      search: $search
    ) {
      products {
        _id
        description
        discountedPrice
        image {
          image
          moduleType
        }
        price
        priority
        quantity
        tags
        title
      }
      totalCount
    }
  }
`

const GET_FAVORITE_PRODUCTS = gql`
  query GetFavoriteProducts {
    GetFavoriteProducts {
      _id
      isFavorite
      productId
      organizationId {
        _id
      }
      createdAt
    }
  }
`

const GET_PRODUCTS_OF_CUSTOMERS = gql`
  query GetProductsOfCustomers($orgId: ID) {
  GetProductsOfCustomers(orgId: $orgId) {
    SKU
    _id
    branchId
    createdAt
    createdBy {
      _id
    }
    description
    discountedPrice
    image {
      _id
      image
      mediaUrl
      moduleType
      reference
    }
    organizationId {
      _id
    }
    price
    priority
    quantity
    tags
    title
    toggleStatus
  }
}
`

export {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_TRASH_PRODUCTS,
  GET_TRASH_PRODUCTS_COUNT,
  GET_PRODUCTS_OF_BRANCH,
  GET_LIVE_PRODUCTS,
  GET_PRODUCTS_OF_BRANCH_PAGINATION,
  GET_FAVORITE_PRODUCTS,
  GET_PRODUCTS_OF_CUSTOMERS
}
