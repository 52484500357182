import { gql } from "graphql-tag"

const ADD_LOYALTY_POINTS = gql`
mutation AddLoyaltyPoints($input: LoyaltyPointsInput!, $percent: Float!) {
    AddLoyaltyPoints(input: $input, percent: $percent) {
      _id
    }
  }
`

const UPDATE_LOYALTY_POINTS = gql`
mutation UpdateLoyaltyPoints($updateLoyaltyPointsId: ID!, $input: LoyaltyPointsUpdateInput!) {
    UpdateLoyaltyPoints(id: $updateLoyaltyPointsId, input: $input) {
      _id
    }
  }
`

export {
  ADD_LOYALTY_POINTS,
  UPDATE_LOYALTY_POINTS,
}
