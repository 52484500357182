import React from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Container } from 'reactstrap'

const NewsLetter = () => {
  return (
    <>
    <div className='newsletter-section'>
        <Container>
            <Row>
                <Col md={4} sm={4} className='align-items-center justify-content-center left-img'>
                    <div className='side-img'>
                        <img src="https://demo2.pavothemes.com/poco/wp-content/uploads/2022/12/h5_decor-left.png" />
                    </div>
                </Col>
                <Col md={4} sm={12} className='text-center bg-cover'>
                    <h3>Newsletter</h3>
                    <h1>Get <span>10%</span> off your Order!</h1>
                    <p>Enter your email and receive a 10% discount on your next order!</p>
                    <Form>
                        <InputGroup className="mb-3 input-holder">
                        <Form.Control
                        placeholder="Enter your email"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        />
                        <Button id="basic-addon2">SUBSCRIBE</Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={4} sm={4} className='d-flex align-items-center justify-content-center right-img'>
                    <div className='side-img'>
                        <img src='https://demo2.pavothemes.com/poco/wp-content/uploads/2022/12/h5_pizza.png'/>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    </>
  )
}

export default NewsLetter