import { gql } from "graphql-tag"

const GET_DEALS = gql`
  query Deals($page: Int!, $perPage: Int!, $isTrash: Boolean, $search: String) {
    Deals(page: $page, perPage: $perPage, isTrash: $isTrash, search: $search) {
      totalCount
      deals {
        _id
        title
        priority
        description
        image
        toggleStatus
        productId
        createdAt
        updatedAt
        deletedAt
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        deletedBy {
          _id
        }
      }
    }
  }
`

const DEALS_WITHOUT_PAGINATION = gql`
  query DealsWithoutPagination {
    DealsWithoutPagination {
      organizationId {
        _id
      }
      _id
      title
    }
  }
`

const GET_DEAL = gql`
  query Query($dealId: ID!) {
    Deal(id: $dealId) {
      _id
      createdAt
      createdBy {
        _id
      }
      deletedAt
      deletedBy {
        _id
      }
      description
      endTime
      image
      organizationId {
        _id
      }
      priority
      productId
      startTime
      status
      title
      toggleStatus
      updatedAt
      updatedBy {
        _id
      }
    }
  }
`

const GET_TRASH_DEALS = gql`
  query GetTrashDeals($page: Int!, $perPage: Int!) {
    GetTrashDeals(page: $page, perPage: $perPage) {
      deals {
        _id
        title
        image
        productId
        createdAt
        updatedAt
        deletedAt
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        deletedBy {
          _id
        }
      }
      totalCount
    }
  }
`

const GET_TRASH_DEALS_COUNT = gql`
  query GetTrashDealsCount {
    GetTrashDealsCount {
      count
    }
  }
`

const GET_DEALS_OF_BRANCH_PAGINATION = gql`
  query Query(
    $page: Int!
    $perPage: Int!
    $getDealsOfBranchPaginationId: ID!
    $orgName: String!
  ) {
    GetDealsOfBranchPagination(
      page: $page
      perPage: $perPage
      id: $getDealsOfBranchPaginationId
      orgName: $orgName
    ) {
      totalCount
      deals {
        _id
        branchId
        title
        image
      }
    }
  }
`

const GET_DEALS_FOR_CUSTOMERS = gql`
  query GetDealsForCustomers($orgId: ID) {
    GetDealsForCustomers(orgId: $orgId) {
      _id
      description
      image {
        image
        moduleType
      }
      title
    }
  }
`

export {
  GET_DEALS,
  GET_DEAL,
  GET_TRASH_DEALS,
  GET_TRASH_DEALS_COUNT,
  DEALS_WITHOUT_PAGINATION,
  GET_DEALS_OF_BRANCH_PAGINATION,
  GET_DEALS_FOR_CUSTOMERS,
}
