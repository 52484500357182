import React from "react"
import Item from "./Item"

const Cart = ({ cart }) => {
  return (
    <div className="cart-items-cont pb-2">
      {cart?.map(item => (
        <Item
          key={item?._id}
          _id={item?._id}
          title={item?.title}
          price={item?.totalPrice}
          quantity={item?.quantity}
          image={item?.image[0]}
          subItems={item?.selectedItemsCartArray}
        />
      ))}
    </div>
  )
}

export default Cart
