const checkoutTypes = {
    PERCENTAGE: "PERCENTAGE",
    FLAT: "FLAT"
};

const CURRENCY_SYMBOL = "Rs";

const loyaltyPointsType = {
    DEBIT: "DEBIT",
    CREDIT: "CREDIT"
}


export {
    checkoutTypes,
    CURRENCY_SYMBOL,
    loyaltyPointsType
}
