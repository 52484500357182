import { gql } from "graphql-tag"

const SIGNIN_USER_MUTATION = gql`
  mutation SignIn($input: SignInInput!) {
    SignIn(input: $input) {
      user {
        _id
        fullName
        email
        organization {
          name
        }
        roles {
          roleId
          isDisabled
        }
        roleType {
          _id
          name
        }
      }
      token
    }
  }
`

const SIGNIN_CUSTOMER = gql`
  mutation SignInCustomer($orgId: ID!, $email: String, $mobile: String) {
    SignInCustomer(orgId: $orgId, email: $email, mobile: $mobile) {
      message
      success
    }
  }
`

const SIGNIN_WITH_OTP = gql`
  mutation SignInWithOTP($otp: String!, $email: String, $mobile: String) {
    SignInWithOTP(otp: $otp, email: $email, mobile: $mobile) {
      token
      user {
        _id
        fullName
        email
        image {
          image
          moduleType
        }
      }
    }
  }
`

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    CreateUser(input: $input) {
      _id
    }
  }
`

const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomerUser($input: CreateGuestCustomerUserInput!) {
    CreateCustomerUser(input: $input) {
      _id
    }
  }
`

const CREATE_GUEST_MUTATION = gql`
  mutation CreateCustomerUser($input: CreateGuestCustomerUserInput!) {
    CreateGuestUser(input: $input) {
      _id
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!, $updateUserId: ID!) {
    UpdateUser(id: $updateUserId, input: $input) {
      _id
      email
      fullName
      blacklistedAt
    }
  }
`
const UPDATE_USER_PROFILE = gql`
  mutation UpdateUser($input: UpdateUserInput!, $updateUserId: ID!) {
    UpdateUser(id: $updateUserId, input: $input) {
      _id
      email
      fullName
      blacklistedAt
    }
  }
`

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($deleteUserId: ID!, $deletedBy: ID!) {
    DeleteUser(id: $deleteUserId, deletedBy: $deletedBy)
  }
`

const CREATE_USER_WITH_ORGANIZATION_MUTATION = gql`
  mutation CreateUserWithOrganization(
    $input: CreateUserInputWithOrganization!
  ) {
    CreateUserWithOrganization(input: $input) {
      _id
    }
  }
`

const FORGET_PASSWORD_MUTATION = gql`
  mutation ForgetPassword($input: ForgetPasswordInput!) {
    ForgetPassword(input: $input) {
      success
      message
      user {
        _id
        email
      }
    }
  }
`
const VERIFY_OTP_MUTATION = gql`
  mutation VerifyOtp($input: VerifyOtpInput!) {
    VerifyOtp(input: $input) {
      message
      success
      user {
        email
        otpCode
      }
    }
  }
`
const VERIFY_ACCOUNT_MUTATION = gql`
  mutation VerifyAccount($input: VerifyAccountInput!) {
    VerifyAccount(input: $input) {
      message
      success
    }
  }
`
const CHANGE_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    ResetPassword(input: $input) {
      message
      success
    }
  }
`
const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    UpdatePassword(input: $input) {
      message
      success
    }
  }
`
const SIGNIN_USER__WITH_GOOGLE_MUTATION = gql`
  mutation SignInWithGoogle($input: SignInWithGoogleInput!) {
    SignInWithGoogle(input: $input) {
      user {
        _id
        fullName
        email
        organization {
          name
        }
        roles {
          roleId
          isDisabled
        }
        roleType {
          _id
          name
        }
      }
      token
    }
  }
`
const SIGNUP_USER__WITH_GOOGLE_MUTATION = gql`
  mutation SignUpWithGoogle($input: CreateUserInputWithOrganization!) {
    SignUpWithGoogle(input: $input) {
      _id
    }
  }
`

const SIGNUP_USER__WITH_ORGANIZATION_GOOGLE_MUTATION = gql`
  mutation SignUpWithOrganizationGoogle(
    $input: SignUpWithOrganizationGoogleInput!
  ) {
    SignUpWithOrganizationGoogle(input: $input) {
      user {
        _id
        fullName
        email
        isExist
        roles {
          roleId
          isDisabled
        }
        roleType {
          _id
          name
        }
      }
      token
    }
  }
`

const LINKEDIN_CODE_MUTATION = gql`
  mutation LinkedInCode($input: linkedinInput!) {
    LinkedInCode(input: $input) {
      email
    }
  }
`

const CREATE_CUSTOMER_REISTER = gql`
  mutation CreateCustomerRegister($input: CreateGuestCustomerUserInput!) {
    CreateCustomerRegister(input: $input) {
      message
      success
    }
  }
`

export {
  SIGNIN_USER_MUTATION,
  CREATE_USER_MUTATION,
  CREATE_CUSTOMER_MUTATION,
  UPDATE_USER_MUTATION,
  DELETE_USER_MUTATION,
  CREATE_USER_WITH_ORGANIZATION_MUTATION,
  FORGET_PASSWORD_MUTATION,
  VERIFY_OTP_MUTATION,
  VERIFY_ACCOUNT_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  SIGNIN_USER__WITH_GOOGLE_MUTATION,
  SIGNUP_USER__WITH_GOOGLE_MUTATION,
  SIGNUP_USER__WITH_ORGANIZATION_GOOGLE_MUTATION,
  LINKEDIN_CODE_MUTATION,
  UPDATE_USER_PROFILE,
  CREATE_GUEST_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  SIGNIN_CUSTOMER,
  SIGNIN_WITH_OTP,
  CREATE_CUSTOMER_REISTER,
}
