import { gql } from "graphql-tag";

const CREATE_DISCOUNT_MUTATION = gql`
mutation CreateDiscount($input: CreateDiscountInput!) {
    CreateDiscount(input: $input) {
      _id
    }
  }
`;

const UPDATE_DISCOUNT_MUTATION = gql`
mutation UpdateDiscount($updateDiscountId: ID!, $input: UpdateDiscountInput!) {
    UpdateDiscount(id: $updateDiscountId, input: $input) {
      _id
    }
  }
`;

const DELETE_DISCOUNT_MUTATION = gql`
mutation DeleteDiscount($deleteDiscountId: ID!) {
    DeleteDiscount(id: $deleteDiscountId) {
      _id
    }
  }
`;

const DELETE_PERMANENTLY_DISCOUNT_MUTATION = gql`
mutation DeleteDiscountPermanently($deleteDiscountPermanentlyId: ID!) {
    DeleteDiscountPermanently(id: $deleteDiscountPermanentlyId) {
      _id
    }
  }
`;

const RESTORE_DISCOUNT_MUTATION = gql`
mutation RestoreDiscount($restoreDiscountId: ID!) {
    RestoreDiscount(id: $restoreDiscountId) {
      _id
    }
  }
`;

const UPDATE_DISCOUNT_STATUS = gql`
mutation UpdateDiscountStatus($updateDiscountStatusId: ID!, $input: UpdateDiscountStatusInput!) {
    UpdateDiscountStatus(id: $updateDiscountStatusId, input: $input) {
      _id
    }
  }
`;

const UPDATE_PROMO_CODE_COUNT = gql`
mutation UpdatePromoCodeCount($promoCode: String!, $userId: ID!) {
  UpdatePromoCodeCount(promoCode: $promoCode, userId: $userId) {
    _id
  }
}
`;

export {
    CREATE_DISCOUNT_MUTATION,
    UPDATE_DISCOUNT_MUTATION,
    DELETE_DISCOUNT_MUTATION,
    DELETE_PERMANENTLY_DISCOUNT_MUTATION,
    RESTORE_DISCOUNT_MUTATION,
    UPDATE_DISCOUNT_STATUS,
    UPDATE_PROMO_CODE_COUNT
};
