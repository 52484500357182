import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { Col, Container, Row } from "reactstrap"
import { isEmpty, map } from "lodash"
import Select from "react-select"

import { Link, useParams } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_PRODUCTS_OF_BRANCH_PAGINATION, GET_PRODUCTS_OF_CUSTOMERS } from "graphql/queries/product"
import Itemslider from "../../components/Banner/Itemslider"
import Card1 from "../../components/Cards/Card1"
import Card2 from "../../components/Cards/Card2"
import Card3 from "../../components/Cards/Card3"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations"
import { useLazyQuery } from "@apollo/client"
import { GET_BRANCHES_BY_ORG_NAME } from "graphql/queries/branch"
import { toast } from "react-toastify"
import NewsLetter from "components/Banner/NewsLetter"
import DeliverySection from "components/Banner/DeliverySection"
import { clearCart } from "store/actions"
import { branchChange, getBranchProducts } from "store/products/actions"
import { GET_DEALS_FOR_CUSTOMERS } from "graphql/queries/deal"
import { GET_GROUPS_BY_BRANCHID } from "graphql/mutations/group"
import Tab1 from "../../components/Tabs/Tab1"
import Slider from "react-slick"
import { useRef } from "react"
import {
  GET_HEADER_NOTICE,
} from "graphql/queries/branch"
const Items = props => {
  //meta title
  document.title = "Items | Skote - React Admin & Dashboard Template"
  const params = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")
  const [branches, setBranches] = useState([])
  const [branch, setBranch] = useState({})
  const [allItems, setAllItems] = useState([])
  const [isBranch, setIsBranch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [theme, setTheme] = useState("1")
  const [groups, setGroups] = useState([])
  const { isChange } = useSelector(state => state.GetBranchProducts)
  const dispatchRedux = useDispatch()
  const [isSticky, setIsSticky] = useState(false)
  const deliverySectionRef = useRef(null);

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // The target element is in the viewport
        $('#tabs-section').removeClass('tab2-stick');
        $('#tabs-section').removeClass('sticky-tabs');
      }
    });
  };

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin around the viewport
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const observer = new IntersectionObserver(callback, options);

    if (deliverySectionRef.current) {
      observer.observe(deliverySectionRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, []);
  const handleScroll = () => {
    if (window.scrollY > 500) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const className =
  theme === '1' ? (isSticky ? 'sticky-tabs' : 'tabs-holder') :
  theme === '3' ? (isSticky ? 'sticky-tabs' : 'tabs-holder') :
  theme === '2' ? (isSticky ? 'tab2-stick' : 'my-5') :
  '';

  const [headerNotice, setHeaderNotice] = useState("")
  const branchId = localStorage.getItem("branchId")
  var {
    data: branchData,
  } = useCustomQuery(GET_HEADER_NOTICE, {
    variables: { getHeaderNoticeId: branchId },
  })
  useEffect(() => {
    if (branchData) {
      setHeaderNotice(branchData?.GetHeaderNotice?.headerNotice)
    }
  }, [branchData])
  const TabMargin = {
    TopMargin: headerNotice ? '100px' : '70px',
  }
  const { products } = useSelector(state => ({
    products: state.ecommerce.products,
  }))

  const { fetchProducts } = useSelector(state => state.GetBranchProducts)

  const [productList, setProductList] = useState([])

  const subdomain = localStorage.getItem("orgDomain")

  var { loading, error, data, execute } = useCustomQuery(
    GET_CONFIGURATIONS_OF_ORG_NAME,
    {
      variables: { orgName: subdomain },
    }
  )
  const orgId = localStorage.getItem("organizationId")

  var {
    loading,
    error,
    data: itemsData,
    execute,
  } = useCustomQuery(GET_PRODUCTS_OF_CUSTOMERS, {
    variables: { orgId: orgId },
  })

  useEffect(() => {
    if (itemsData) {
      const itemsDataAll = itemsData?.GetProductsOfCustomers

      setAllItems(itemsDataAll)
    }
  }, [itemsData])

  useEffect(() => {
    if (data) {
      const siteConfigData = data?.GetSettingsOfOrganizationName
      setTheme(siteConfigData[0]?.theme)
    }
  }, [data])
  const [getAllBranches, { loading: getAllBraches, __, branchesData }] =
    useLazyQuery(GET_BRANCHES_BY_ORG_NAME, {
      onCompleted: data => {
        if (data?.GetAllActiveBrachesByOrgName.length == 0) {
          toast.error("No data found")
          setIsBranch(false)
        } else {
          setIsBranch(true)
          setBranches(
            data?.GetAllActiveBrachesByOrgName.map(branch => ({
              value: branch._id,
              label: branch.title,
            }))
          )
        }
      },
    })

  const getBranches = () => {
    getAllBranches({
      variables: {
        orgName: subdomain,
      },
    })
  }

  useEffect(() => {
    if (subdomain) {
      getBranches()
    }
  }, [subdomain])
  const getItems = () => {
    dispatch(getBranchProducts(false))
    const siteSetting = sessionStorage.getItem("siteSettings")
    let siteSettings
    if (siteSetting == undefined) {
      siteSettings = []
    } else {
      siteSettings = JSON.parse(siteSetting)
    }
    const branchObj = JSON.parse(localStorage.getItem("selectedBranch"))
    const branchValue = branchObj?.value
    setTheme(siteSettings?.theme)
    if (branch.value || branchValue) {
      localStorage.setItem("orgName", subdomain)
      localStorage.setItem("branchId", branchValue)
      const organizationName = localStorage.getItem("organizationName")

      getProductsOfbranch({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm,
          getProductsOfBranchPaginationId: branchValue,
          orgName: organizationName,
        },
      })
      setModalShow(false)
    }
  }

  useEffect(() => {
    if (fetchProducts == true) {
      getItems()
    }
  }, [fetchProducts])

  useEffect(() => {
    getItems()
  }, [])

  const [modalShow, setModalShow] = React.useState(false)

  const [getProductsOfbranch, { loading: getAllProducts, ____, productsData }] =
    useLazyQuery(GET_PRODUCTS_OF_BRANCH_PAGINATION, {
      onCompleted: data => {
        if (data?.GetProductsOfBranchPagination.products.length == 0) {
          toast.error("No data found")
          setIsBranch(false)
        } else {
          setIsBranch(true)
          setProductList(data.GetProductsOfBranchPagination.products)
        }
      },
    })

  useEffect(() => {
    const branchObj = JSON.parse(localStorage.getItem("selectedBranch"))
    const getBranchValue = branchObj?.value
    if (getBranchValue) {
      setModalShow(false)
    } else {
      setModalShow(true)
    }
  }, [])

  const handleSelectBranch = e => {
    setBranch(e)
    localStorage.setItem("selectedBranch", JSON.stringify(e))
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: styles => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const [getAllGroups, { loading: getAllGroupsLoading, ___, groupsData }] =
    useLazyQuery(GET_GROUPS_BY_BRANCHID, {
      onCompleted: data => {
        setGroups(data?.GroupsByBranchId)
        dispatchRedux(branchChange(false))
      },
    })

  useEffect(() => {
    getAllGroups({
      variables: {
        branchId: branchId,
      },
    })
  }, [fetchProducts])

  const CardComponent =
    theme === "1" ? Card1 : theme === "2" ? Card2 : theme === "3" ? Card3 : null
    var settings = {
      autoplay: true,
      dots: false,
      centerMode: false,
      infinite: false,
      speed: 500,
      slidesToShow: theme === "2" ? 2 : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: theme === "2" ? 1 : 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
            centerMode: theme === "2" ? true : false,
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: true,
            slidesToShow: theme === "2" ? 1 : 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    const getOrgName = localStorage.getItem("organizationName")

  return (
    <React.Fragment>
      <Modal
        onHide={handleClose}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      > 
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Branch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className={"mb-4 align-items-center"}>
            <Col md={12}>
              <Select
                styles={colourStyles}
                type="text"
                placeholder="Select a branch"
                id="name"
                onChange={handleSelectBranch}
                options={branches}
                value={branch}
                className="submain-one-form-body-subsection-select"
              />
            </Col>
          </Row>
          <Button
            onClick={() => [getItems(), dispatch(clearCart())]}
            className="page-btn w-100"
          >
            Proceed
          </Button>
        </Modal.Body>
      </Modal>
      <div className="page-content page-padding" style={{
        '--top-margin': TabMargin.TopMargin,
      }}>
        <Itemslider />
        <div className="sections-holder">
          <div className={theme == "2" ? "product-section container" : "products-section"}>
            <div id='tabs-section' className={"tabs-section " + (theme == "2" ? "col-md-3 " : " ") + (className)}>
                <Tab1 groups={groups} theme={theme} />
            </div> 
            <Container className={"cards-sections " + (theme == "2" ? "col-md-9" : "pt-5")}>
              {/* this row is for deals */}
              {groups?.map(group => {
                const itemsInGroup = allItems.filter(item =>
                  group?.products.some(product => product?.productId?._id === item?._id)
                )
                if (itemsInGroup.length === 0) return null

                return (
                  <Row
                    key={group?._id}
                    className={
                      theme == "2" ? "pt-5" : "py-4"
                    }
                  >
                    <h2 id={group?._id} className="mb-4">
                      {group?.title}
                    </h2>
                    {group?.banner.length > 0 ? (
                      <>
                        {group?.banner.map((img)=> (
                          <div key={img?._id}>
                            <img  className='category-banner' src={`${process.env.REACT_APP_APOLLO_SERVER_URL}/${getOrgName}/${img?.moduleType}/${img?.image}`}/>
                          </div>
                        ))}
                      </>
                    ): null}
                    <Slider {...settings} className="card-slider px-0">
                      {itemsInGroup?.map(item => {
                        const cardProps = {
                          title: item?.title,
                          image: item?.image,
                          tags: item?.tags,
                          description: item?.description,
                          price: item?.price,
                          discountedPrice: item?.discountedPrice,
                        }
                        return (
                          <Col
                            key={item._id}
                            md={theme === "2" ? "6" : "3"}
                            sm={theme === "2" ? "6" : ""}
                            className={`product${theme === "1" ? " product1" : ""}`}
                          >
                            {theme === "1" && (
                                <Card1 product={item} {...cardProps} />
                            )}
                            {theme === "2" && (
                                <Card2 trendingItems={false} product={item} {...cardProps} />
                            )}
                            {theme === "3" && (
                                <Card3 product={item} {...cardProps} />
                            )}
                          </Col>
                        )
                      })}
                    </Slider>
                  </Row>
                )
              })}


            </Container>
          </div>
          <div ref={deliverySectionRef}></div>
        </div>
        <DeliverySection/>
        <NewsLetter />
      </div>
    </React.Fragment>
  )
}

Items.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
}

export default withRouter(Items)
