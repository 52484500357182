import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import { store, persistor } from "./store"
import { ApolloProvider } from "@apollo/client"
import ErrorBoundary from "components/ErrorBoundary"
import { PersistGate } from "redux-persist/integration/react"
import client from "./apollo-config"
import { AuthContextProvider } from "context/AuthContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.Fragment>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthContextProvider>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </BrowserRouter>
          </AuthContextProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.Fragment>
)

serviceWorker.unregister()
