import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Container } from 'reactstrap'
const DeliverySection = () => {
  
  return (
    <>
        <Container className='delivery-section my-5' id='delivery-section'>
            <Row>
                <Col md={12} className='text-center delivery-text'>
                    <h1>Are you ready to order with the best deals?</h1>
                    <Form>
                        <Button id="basic-addon2">Proceed to order</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default DeliverySection