import React from "react"
import Counter from "./Counter"
import { useDispatch } from "react-redux"
import { getSubTotalPrice, removeProductSuccess } from "store/actions"
import { Col } from "reactstrap"
import { Row } from "react-bootstrap"
import { Badge } from "reactstrap"
import img from "../../../src/assets/images/demi_food.jpg"

const Item = ({ title, key, _id, price, quantity, image, subItems }) => {
  const dispatch = useDispatch()
  const getOrgName = localStorage.getItem("organizationName")
  const getImageSrc = () => {
    if (
      image?.image === undefined ||
      image?.image == "" ||
      image?.image == null
    ) {
      return img
    } else {
      return `${process.env.REACT_APP_APOLLO_SERVER_URL}/${getOrgName}/${image?.moduleType}/${image?.image}`
    }
  }
  return (
    <Row className="cart-item p-0 mx-0" key={key}>
      <Col md={5} className="px-0">
        <img
          src={getImageSrc()}
        />
      </Col>
      <Col md={7} className="d-flex flex-column justify-content-between cart-item-desc">
        <div className="cart-item-name">{title}</div>
        {subItems?.length > 0 && (
          <div className="d-flex">
            {subItems?.map(({ name, count }, index) => (
              <p className="mx-1" key={name}>
                {count} {name}{index < subItems.length - 1 ? ', ' : ''}
              </p>
            ))}
          </div>
        )}


        <div className=" cart-item-price mt-2">
          Rs {price * quantity}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <Counter quantity={quantity} _id={_id} />
          <p
            onClick={() => [
              dispatch(removeProductSuccess(_id)),
              dispatch(getSubTotalPrice()),
            ]}
            className="counter-style"
            style={{
              marginRight: 0,
              marginLeft: "auto",
            }}
          >
            <i className="mdi mdi-trash-can"></i>
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default Item
