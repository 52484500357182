import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { Col, Container, Row } from "reactstrap"
import { isEmpty } from "lodash"
import Select from "react-select"

import { useParams } from "react-router-dom"
//Import Breadcrumb

//redux
import { useSelector, useDispatch } from "react-redux"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_PRODUCTS_OF_BRANCH_PAGINATION } from "graphql/queries/product"
import Itemslider from "../../components/Banner/Itemslider"
import Card1 from "../../components/Cards/Card1"
import Card2 from "../../components/Cards/Card2"
import Card3 from "../../components/Cards/Card3"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { GET_CONFIGURATIONS_OF_ORG_NAME } from "graphql/queries/configurations"
import { useLazyQuery } from "@apollo/client"
import { GET_BRANCHES_BY_ORG_NAME } from "graphql/queries/branch"
import { toast } from "react-toastify"
import NewsLetter from "components/Banner/NewsLetter"
import DeliverySection from "components/Banner/DeliverySection"
import { clearCart } from "store/actions"
import { branchChange, getBranchProducts } from "store/products/actions"
import { GET_DEALS_FOR_CUSTOMERS } from "graphql/queries/deal"
import { GET_GROUPS_BY_BRANCHID } from "graphql/mutations/group"
import Tab1 from "../../components/Tabs/Tab1"
import Slider from "react-slick"
const DemoItems = props => {
  //meta title
  document.title = "Items | Skote - React Admin & Dashboard Template"
  const params = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")
  const [branches, setBranches] = useState([])
  const [branch, setBranch] = useState(
    JSON.parse(localStorage.getItem("selectedBranch")) || {}
  )
  const [allDeals, setAllDeals] = useState([])
  const [isBranch, setIsBranch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [theme, setTheme] = useState(params?.themeId)
  const [groups, setGroups] = useState([])
  const { isChange } = useSelector(state => state.GetBranchProducts)

  const { products } = useSelector(state => ({
    products: state.ecommerce.products,
  }))

  const { fetchProducts } = useSelector(state => state.GetBranchProducts)

  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")

  const subdomain = localStorage.getItem("orgDomain")

  var { loading, error, data, execute } = useCustomQuery(
    GET_CONFIGURATIONS_OF_ORG_NAME,
    {
      variables: { orgName: subdomain },
    }
  )
  const orgId = localStorage.getItem("organizationId")
  var {
    loading,
    error,
    data: dealsData,
    execute,
  } = useCustomQuery(GET_DEALS_FOR_CUSTOMERS, {
    variables: { orgId: orgId },
  })

  useEffect(() => {
    if (dealsData) {
      const dealsDataAll = dealsData?.GetDealsForCustomers

      setAllDeals(dealsDataAll)
    }
  }, [dealsData])

  useEffect(() => {
    if (data) {
      const siteConfigData = data?.GetSettingsOfOrganizationName
    }
  }, [data])

  const [getAllBranches, { loading: getAllBraches, __, branchesData }] =
    useLazyQuery(GET_BRANCHES_BY_ORG_NAME, {
      onCompleted: data => {
        if (data?.GetAllActiveBrachesByOrgName.length == 0) {
          toast.error("No data found")
          setIsBranch(false)
        } else {
          setIsBranch(true)
          setBranches(
            data?.GetAllActiveBrachesByOrgName.map(branch => ({
              value: branch._id,
              label: branch.title,
            }))
          )
        }
      },
    })

  const getBranches = () => {
    getAllBranches({
      variables: {
        orgName: subdomain,
      },
    })
  }

  useEffect(() => {
    if (subdomain) {
      getBranches()
    }
  }, [subdomain])
  const getItems = () => {
    dispatch(getBranchProducts(false))
    const siteSetting = sessionStorage.getItem("siteSettings")
    let siteSettings
    if (siteSetting == undefined) {
      siteSettings = []
    } else {
      siteSettings = JSON.parse(siteSetting)
    }
    const branchObj = JSON.parse(localStorage.getItem("selectedBranch"))
    const branchValue = branchObj?.value
    if (branch.value || branchValue) {
      localStorage.setItem("orgName", subdomain)
      localStorage.setItem("branchId", branchValue)

      getProductsOfbranch({
        variables: {
          page: page,
          perPage: perPage,
          isTrash: false,
          search: searchTerm,
          getProductsOfBranchPaginationId: branchValue,
          orgName: subdomain,
        },
      })
      setModalShow(false)
    }
  }

  useEffect(() => {
    if (fetchProducts == true) {
      getItems()
    }
  }, [fetchProducts])

  useEffect(() => {
    getItems()
  }, [])

  const [modalShow, setModalShow] = React.useState(false)

  const [getProductsOfbranch, { loading: getAllProducts, ____, productsData }] =
    useLazyQuery(GET_PRODUCTS_OF_BRANCH_PAGINATION, {
      onCompleted: data => {
        if (data?.GetProductsOfBranchPagination.products.length == 0) {
          toast.error("No data found")
          setIsBranch(false)
        } else {
          setIsBranch(true)
          setProductList(data.GetProductsOfBranchPagination.products)
        }
      },
    })

  useEffect(() => {
    const branchObj = JSON.parse(localStorage.getItem("selectedBranch"))
    const getBranchValue = branchObj?.value
    if (getBranchValue) {
      setModalShow(false)
    } else {
      setModalShow(true)
    }
  }, [])

  const handleSelectBranch = e => {
    setBranch(e)
    localStorage.setItem("selectedBranch", JSON.stringify(e))
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: styles => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
    }),
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const branchId = localStorage.getItem("branchId")

  const [getAllGroups, { loading: getAllGroupsLoading, ___, groupsData }] =
    useLazyQuery(GET_GROUPS_BY_BRANCHID, {
      onCompleted: data => {
        setGroups(data?.GroupsByBranchId)
        dispatch(branchChange(false))
      },
    })

  useEffect(() => {
    getAllGroups({
      variables: {
        branchId: branchId,
      },
    })
  }, [fetchProducts])
  var settings = {
    autoplay: true,
    dots: false,
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: theme === "2" ? 2 : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: theme === "2" ? 2 : 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 800,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <React.Fragment>
      <Modal
        onHide={handleClose}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Branch
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className={"mb-4 align-items-center"}>
            <Col md={12}>
              <Select
                styles={colourStyles}
                type="text"
                placeholder="Select a branch"
                id="name"
                onChange={handleSelectBranch}
                options={branches}
                value={branch}
                className="submain-one-form-body-subsection-select"
              />
            </Col>
          </Row>
          <Button
            onClick={() => [getItems(), dispatch(clearCart())]}
            className="page-btn w-100"
          >
            Proceed
          </Button>
        </Modal.Body>
      </Modal>
      <div className="page-content page-padding">
        <Itemslider />
        <div className="sections-holder">
        <div className={theme == "2" ? "product-section container" : "products-section"}>
          <div className={"tabs-section " + (theme == "2" ? "col-md-3" : "")}>
            <Tab1 theme={theme} groups={groups} />
          </div>
          <Container className={"cards-sections " + (theme == "2" ? "col-md-9" : "pt-5")}>
            {/* this row is for deals */}
            {groups?.map(group => {
              return (
                <Row
                  key={group?._id}
                  className={theme == "2" ? "py-5 border-bottom" : "border-bottom py-4"}
                >
                  <h2 id={group?._id} className="mb-4">
                    {group?.title}
                  </h2>
                  <Slider {...settings} className="card-slider px-0">
                  {!isEmpty(allDeals) &&
                    allDeals
                      ?.filter(deal => {
                        const specificItemIds = group?.sections.map(
                          section => section.sectionId
                        )
                        return specificItemIds.includes(deal?._id)
                      })
                      ?.map((deal, key) => {
                        return (
                          <>
                            <Col
                              key={deal._id}
                              md={theme == "2" ? "6" : "3"}
                              sm={theme == "2" ? "6" : ""}
                              className={
                                theme == "1" ? "product product1" : "product"
                              }
                            >
                              {theme == "1" ? (
                                <Card1
                                  product={deal}
                                  title={deal?.title}
                                  image={deal?.image[0]}
                                  price={200}
                                  discountedPrice={100}
                                />
                              ) : theme == "2" ? (
                                <Card2
                                  product={deal}
                                  title={deal?.title}
                                  image={deal?.image[0]}
                                  price={200}
                                  discountedPrice={100}
                                />
                              ) : theme == "3" ? (
                                <Card3
                                  product={deal}
                                  title={deal?.title}
                                  image={deal?.image[0]}
                                  price={200}
                                  discountedPrice={100}
                                />
                              ) : null}
                            </Col>
                          </>
                        )
                      })}
                  </Slider>
                </Row>
              )
            })}

            <Row className={theme == "2" ? "my-5" : "my-3"}>
              <h2 className="mb-4">EveryDay Value</h2>
              <Slider {...settings} className="card-slider px-0">
                {!isEmpty(productList) &&
                  productList?.map((product, key) => (
                    <Col
                      key={product._id}
                      md={theme == "2" ? "6" : "3"}
                      className={theme == "1" ? "product product1" : "product"}
                    >
                      {theme == "1" ? (
                        <Card1
                          product={product}
                          title={product?.title}
                          image={product?.image[0]}
                          price={product?.price}
                          discountedPrice={product?.discountedPrice}
                        />
                      ) : theme == "2" ? (
                        <Card2
                          product={product}
                          title={product?.title}
                          image={product?.image[0]}
                          price={product?.price}
                          discountedPrice={product?.discountedPrice}
                        />
                      ) : theme == "3" ? (
                        <Card3
                          product={product}
                          title={product?.title}
                          image={product?.image[0]}
                          price={product?.price}
                          discountedPrice={product?.discountedPrice}
                        />
                      ) : null}
                    </Col>
                  ))}
              </Slider>
            </Row>
          </Container>
        </div>
        </div>
        
        <NewsLetter />
        <DeliverySection />
      </div>
    </React.Fragment>
  )
}

DemoItems.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
}

export default withRouter(DemoItems)
