import { gql } from "graphql-tag";

const GET_GROUPS = gql`
query Query($page: Int!, $isTrash: Boolean, $search: String, $perPage: Int!) {
  GroupsByOrganizationId(page: $page, isTrash: $isTrash, search: $search, perPage: $perPage) {
    groups {
      _id
      title
      sections {
        _id
        title
      }
    }
    totalCount
  }
}
`;


const GET_GROUP_BY_ID = gql`
query Query($groupByIdId: ID!) {
  GroupById(id: $groupByIdId) {
    _id
    sections {
      _id
      title
    }
    title
  }
}
`;

const GET_GROUPS_OF_BRANCH_PAGINATION = gql`
query Query($page: Int!, $perPage: Int!, $getGroupsOfBranchPaginationId: ID!, $orgName: String!) {
  GetGroupsOfBranchPagination(page: $page, perPage: $perPage, id: $getGroupsOfBranchPaginationId, orgName: $orgName) {
    groups {
      _id
      branchId
      title
    }
    totalCount
  }
}
`



export {
    GET_GROUPS,
    GET_GROUP_BY_ID,
    GET_GROUPS_OF_BRANCH_PAGINATION
};
