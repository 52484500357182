import React, {useState, useEffect} from 'react'
import { Col, Container, Row } from 'reactstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import useCustomQuery from 'graphql/hooks/useCustomQuery';
import { GET_WEB_BANNER_FOR_CUSTOMER } from 'graphql/queries/webBanners';
const Itemslider = () => {

  const [images, setImages] = useState([])
    var settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const orgId = localStorage.getItem("organizationId")
      const branchId = localStorage.getItem("branchId")
      const orgName = localStorage.getItem("organizationName")

      const { loading: web_banner_loading, data: webBannerData } = useCustomQuery(
        GET_WEB_BANNER_FOR_CUSTOMER,
        {
          variables: {
            orgId: orgId,
            branchId: branchId
          },
        }
      )

      useEffect(()=> {

        if(webBannerData){
          setImages(webBannerData?.GetWebBannersForCustomer)
        }

      }, [webBannerData])



  return (
    <>
    <Container className='slider-holder my-4'>
      <Row>
        <Col md={12}>
          <Slider {...settings}>
            {images?.map((image, index)=> {
              return (
                <div key={index} className='position-relative image-holder'>
                  <img src={`${process.env.REACT_APP_APOLLO_SERVER_URL}/${orgName}/${image?.image[0]?.moduleType}/${image?.image[0]?.image}`} alt={`Image ${index}`} />
                </div>
              )
            })}
          </Slider>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Itemslider