import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Items from "pages/Items";
import Cart from "pages/Cart";
import Deals from "pages/Deals";
import Groups from "pages/Groups";
import DemoItems from "pages/DemoItems";
import Favorites from "pages/Favorites";
import OrderHistory from "pages/OrderHistory";
import OrderItems from "pages/OrderHistory/invoices-detail";
import OrderTracking from "pages/Cart/OrderTracking";
import ItemDetail from "pages/Items/ItemDetail";

const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },


  { path: "/items", component: <Items /> },
  { path: "/item/:itemName/:itemId", component: <ItemDetail /> },
  { path: "/checkout", component: <Cart /> },
  { path: "/order-track/:id", component: <OrderTracking /> },
  { path: "/demoItems/:themeId", component: <DemoItems /> },
  { path: "/deals", component: <Deals /> },
  { path: "/groups", component: <Groups /> },
  { path: "/favorites", component: <Favorites /> },


  //Invoices
  { path: "/invoices", component: <OrderHistory /> },
  { path: "/invoice-detail/:id", component: <OrderItems /> },


  {
    path: "/",
    exact: true,
    component: < Navigate to="/items" />,
  },
];

const publicRoutes = [

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },


];

export { authProtectedRoutes, publicRoutes };
