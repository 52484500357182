import { gql } from "graphql-tag";

const GET_DISCOUNT = gql`
query Query($getDiscountId: ID!) {
    GetDiscount(id: $getDiscountId) {
      _id
      promoCode
      startTime
      endTime
      discountType
      discount
      firstTimeUser
      usedCount
      maxCount
      maxUserCount
      miniOrderValue
      OrderType
      status
      Branches {
        _id
      }
      Deals {
        _id
      }
      organizationId {
        _id
      }
      deletedAt
      createdBy {
        _id
      }
      updatedBy {
        _id
      }
      deletedBy {
        _id
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_DISCOUNTS = gql`
query GetDiscounts($page: Int!, $perPage: Int!) {
    GetDiscounts(page: $page, perPage: $perPage) {
      discounts {
        _id
        promoCode
        startTime
        endTime
        discountType
        discount
        firstTimeUser
        usedCount
        maxCount
        maxUserCount
        miniOrderValue
        OrderType
        status
        Branches {
          _id
        }
        Deals {
          _id
        }
        organizationId {
          _id
        }
        deletedAt
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        deletedBy {
          _id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_TRASH_DISCOUNTS = gql`
query GetTrashDiscounts($page: Int!, $perPage: Int!) {
    GetTrashDiscounts(page: $page, perPage: $perPage) {
      discounts {
        _id
        promoCode
        startTime
        endTime
        discountType
        discount
        firstTimeUser
        usedCount
        maxCount
        maxUserCount
        miniOrderValue
        OrderType
        status
        Branches {
          _id
        }
        Deals {
          _id
        }
        organizationId {
          _id
        }
        deletedAt
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        deletedBy {
          _id
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const GET_TRASH_DISCOUNTS_COUNT = gql`
query GetTrashDiscountsCount {
    GetTrashDiscountsCount {
      count
    }
  }
`;

const GET_ORDER_TYPE_STATUS = gql`
query GetOrderTypeStatus {
  GetOrderTypeStatus {
    OrderType
  }
}
`;

const GET_DISCOUNT_STATUS = gql`
query GetDiscountStatus {
  GetDiscountStatus {
    discountType
  }
}
`;

const GET_PROMO_CODES = gql`
query GetPromoCode($promoCode: String!) {
  GetPromoCode(promoCode: $promoCode) {
    promoCode
    _id
    discount
    miniOrderValue
    maxUserCount
    maxCount
    firstTimeUser
    discountType
    OrderType
    usedCustomerCount {
      count
      userId
    }
    Branches {
      _id
    }
    items {
      _id
    }
    endTime
    startTime
    status
    usedCount
    organizationId {
      _id
    }
  }
}
`;

export {
  GET_DISCOUNT,
  GET_DISCOUNTS,
  GET_TRASH_DISCOUNTS,
  GET_TRASH_DISCOUNTS_COUNT,
  GET_ORDER_TYPE_STATUS,
  GET_DISCOUNT_STATUS,
  GET_PROMO_CODES,
};
