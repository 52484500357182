import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty, map } from "lodash";

//Import Star Ratings
import StarRatings from "react-star-ratings";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import data
import { discountData, productsData } from "common/data";

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import useCustomQuery from "graphql/hooks/useCustomQuery";
import { GET_PRODUCTS_OF_BRANCH_PAGINATION } from "graphql/queries/product";
import { GET_DEALS_OF_BRANCH_PAGINATION } from "graphql/queries/deal";

const Deals = props => {

  //meta title
  document.title = "Items | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")

  const { products } = useSelector(state => ({
    products: state.ecommerce.products,
  }));

  // eslint-disable-next-line no-unused-vars
  const [FilterClothes, setFilterClothes] = useState([
    { id: 1, name: "T-shirts", link: "#" },
    { id: 2, name: "Shirts", link: "#" },
    { id: 3, name: "Jeans", link: "#" },
    { id: 4, name: "Jackets", link: "#" },
  ]);
  const [productList, setProductList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  // eslint-disable-next-line no-unused-vars
  const [discountDataList, setDiscountDataList] = useState([]);
  const [filters, setFilters] = useState({
    discount: [],
    price: { min: 0, max: 500 },
  });
  // eslint-disable-next-line no-unused-vars
  const [totalPage, setTotalPage] = useState(5);

  // useEffect(() => {
  //   setProductList(products);
  //   setDiscountDataList(discountData);
  // }, [products, discountData]);

  // useEffect(() => {
  //   dispatch(onGetProducts());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (!isEmpty(products)) setProductList(products);
  // }, [products]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onSelectDiscount = e => {
    const { value, checked } = e.target;
    const { discount } = filters;
    var existing = [...discount];
    if (checked) {
      existing = [...discount, value];
      setFilters({
        ...filters,
        discount: existing,
      });
    } else {
      const unCheckedItem = discount.find(item => item === value);
      if (unCheckedItem) {
        existing = discount.filter(item => item !== value);
      }
    }
    setFilters({
      ...filters,
      discount: existing,
    });
    // onFilterProducts(value, checked)

    let filteredProducts = productsData;
    if (checked && parseInt(value) === 0) {
      filteredProducts = productsData.filter(product => product.offer < 10);
    } else if (checked && existing.length > 0) {
      filteredProducts = productsData.filter(
        product => product.offer >= Math.min(...existing)
      );
    }
    setProductList(filteredProducts);
  };
  const onUpdate = (render, handle, value) => {
    setProductList(
      productsData.filter(
        product => product.newPrice >= value[0] && product.newPrice <= value[1]
      )
    );
  };

  const [ratingvalues, setRatingvalues] = useState([]);
  /*
  on change rating checkbox method
  */
  const onChangeRating = value => {
    setProductList(productsData.filter(product => product.rating >= value));

    var modifiedRating = [...ratingvalues];
    modifiedRating.push(value);
    setRatingvalues(modifiedRating);
  };

  const onSelectRating = value => {
    setProductList(productsData.filter(product => product.rating === value));
  };

  const onUncheckMark = (value) => {
    var modifiedRating = [...ratingvalues];
    const modifiedData = (modifiedRating || []).filter(x => x !== value);
    /*
    find min values
    */
    var filteredProducts = productsData;
    if (modifiedData && modifiedData.length && value !== 1) {
      var minValue = Math.min(...modifiedData);
      if (minValue && minValue !== Infinity) {
        filteredProducts = productsData.filter(
          product => product.rating >= minValue
        );
        setRatingvalues(modifiedData);
      }
    } else {
      filteredProducts = productsData;
    }
    setProductList(filteredProducts);
  };

  const handlePageClick = page => {
    setPage(page);
  };


  const orgName = localStorage.getItem("orgName")
  const branchId = localStorage.getItem("branchId")


  const {
    loading: getBranchItemsLoading,
    _,
    data: Deals,
    execute,
  } = useCustomQuery(GET_DEALS_OF_BRANCH_PAGINATION, {
    variables: {
      page: page,
      perPage: perPage,
      isTrash: false,
      search: searchTerm,
      getDealsOfBranchPaginationId: branchId,
      orgName: orgName,
    },
  })

  useEffect(()=> {
    if(Deals){
      setProductList(Deals.GetDealsOfBranchPagination.deals)
    }
  }, [Deals])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Deals" breadcrumbItem="Deals" />
          <Row>
            
            <Col lg={12}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2">
                    {/* <h5>Items</h5> */}
                  </div>
                </Col>
                <Col lg={8} sm={6}>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl={4} sm={6} key={"_col_" + key}>
                      <Card
                        onClick={() =>
                          navigate(
                            `/ecommerce-product-detail/${product._id}`
                          )
                        }
                      >
                        <CardBody>
                          <div className="product-img position-relative">
                            {product.isOffer ? (
                              <div className="avatar-sm product-ribbon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  {`- ${product.offer} %`}
                                </span>
                              </div>
                            ) : null}

                            <img
                              src={product.image[0]}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <h5 className="mb-3 text-truncate">
                              <Link
                                to={"/ecommerce-product-detail/" + product._id}
                                className="text-dark"
                              >
                                {product.title}{" "}
                              </Link>
                            </h5>
                            <div className="text-muted mb-3">
                              <StarRatings
                                rating={product.rating}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#74788d"
                                numberOfStars={5}
                                name="rating"
                                starDimension="14px"
                                starSpacing="1px"
                              />
                            </div>
                            <h5 className="my-0">
                              <span className="text-muted me-2">
                                <del>${product?.oldPrice}</del>
                              </span>
                              <b>${product?.price}</b>
                            </h5>
                            <Button style={{marginTop: '15px'}}>Add to cart</Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col lg={12}>
                  <Pagination className="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        to="#"
                        onClick={() => handlePageClick(page - 1)}
                      />
                    </PaginationItem>
                    {map(Array(totalPage), (item, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink
                          onClick={() => handlePageClick(i + 1)}
                          to="#"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={page === totalPage}>
                      <PaginationLink
                        next
                        to="#"
                        onClick={() => handlePageClick(page + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Deals.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(Deals);
