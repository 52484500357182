import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  toggleCartSidebar
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

//redux
import { useSelector, useDispatch } from "react-redux";
import Topbar from "./Topbar";
import CartSidebar from "components/CartSidebar/CartSidebar";
import { useState } from "react";

const Layout = props => {
  const dispatch = useDispatch();
  const [colors, setColors] = useState();
  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType,
    showCartSidebar
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    layoutModeType: state.Layout.layoutModeType,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    showCartSidebar:state.Cart.showCartSidebar
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  //hides cart sidebar on body click
  const cartRightbar = (event) => {
    var rightbar = document.getElementById("cart-sidebar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(toggleCartSidebar(false));
    }
  };

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);
    document.body.addEventListener("click", cartRightbar, true);

    return () => {
      document.body.removeEventListener("click", cartRightbar);
    };

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  const themeColors = sessionStorage.getItem("siteSettings")
  useEffect(() => {
    if (themeColors == undefined) {
      setColors([])
    } else {
      setColors(JSON.parse(themeColors))
    }
  }, [themeColors])
  const ThemeVariables = {
    Accent:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[0]?.value
        : "#fff",
    AccentText:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[1]?.value
        : "#fff",
    Button:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[2]?.value
        : "#555b6d",
    ButtonText:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[3]?.value
        : "#fff",
    ButtonHover:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[4]?.value
        : "#000",
    ButtonHoverText:
      colors?.themeColors?.length > 0
        ? colors?.themeColors[5]?.value
        : "#000",
  }
  return (
    <React.Fragment>
      <div id="layout-wrapper" style={{
          "--accent-bg": ThemeVariables.Accent,
          "--accent-text": ThemeVariables.AccentText,
          "--theme-button-bg": ThemeVariables.Button,
          "--theme-button-text": ThemeVariables.ButtonText,
          "--theme-button-hover-bg": ThemeVariables.ButtonHover,
          "--theme-button-hover-text": ThemeVariables.ButtonHoverText,
        }}>
        <Header toggleMenuCallback={toggleMenuCallback} />
     
        <div className="main-content" id="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
      {showCartSidebar ? <CartSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
