import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { useParams } from "react-router-dom"
import NewsLetter from "components/Banner/NewsLetter"
import { Accordion } from "react-bootstrap";
import img7 from "../../assets/images/product/img-7.png"
import { Card, CardBody, Col, Button, Container, Row } from "reactstrap"
import { GET_PRODUCT } from "graphql/queries/product"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import Slider from "react-slick"
import imgDefault from "../../../src/assets/images/demi_food.jpg"
import { useDispatch, useSelector } from "react-redux"
import { addProductSuccess, getSubTotalPrice, toggleCartSidebar } from "store/actions"
import { CURRENCY_SYMBOL } from "constants/modules"
import { toast } from "react-toastify"

const ItemDetail = props => {
  //meta title
  const params = useParams()
  const { cart } = useSelector(state => state.Cart)
  const [valueCounts, setValueCounts] = useState({});
  const [selectedSubValues, setSelectedSubValues] = useState([]);
  const [mandatoryVariationSelected, setMandatoryVariationSelected] = useState(false);

  const selectedBranch = localStorage.getItem('selectedBranch') ?
    JSON.parse(localStorage.getItem('selectedBranch')) : {}
  const [branchId, setBranchId] = useState({ ...selectedBranch })

  var { loading, error, data, execute } = useCustomQuery(GET_PRODUCT, {
    variables: { productId: params.itemId },
  })

  const price = data?.Product?.discountedPrice > 0 ? data?.Product?.discountedPrice : data?.Product?.price || 0;
  const [totalPrice, setTotalPrice] = useState(price || 0);
  const dispatch = useDispatch()

  useEffect(() => {
    let newTotalPrice = price || 0;

    selectedSubValues.forEach((subValueName) => {
      const subValue = data?.Product?.variations?.flatMap((variation) => variation.values)
        .find((subValue) => subValue.name === subValueName);

      if (subValue) {
        const count = valueCounts[subValueName] || 0;
        newTotalPrice += ((subValue.price - subValue.discount) * count);
      }
    });
    setTotalPrice(newTotalPrice);
    ;

    const mandatoryVariations = data?.Product?.variations?.filter((variation) => variation.isMandatory);

    const isMandatoryVariationSelected = mandatoryVariations?.every((variation) =>
      selectedSubValues.some((value) =>
        variation.values.some((subValue) => subValue.name === value)
      )
    );

    setMandatoryVariationSelected(isMandatoryVariationSelected);


  }, [data?.Product, selectedSubValues, valueCounts]);

  document.title = `Item | ${params.itemName}`


  const getOrgName = localStorage.getItem("organizationName")

  const settings = {
    // Slick settings go here
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };

  const getImageSrc = (img) => {
    if (
      img?.image === undefined ||
      img?.image == "" ||
      img?.image == null
    ) {
      return imgDefault
    } else {
      return `${process.env.REACT_APP_APOLLO_SERVER_URL}/${getOrgName}/${img?.moduleType}/${img?.image}`
    }
  }

  const handleSubValueChange = (subValue, isMultiSelect, allValues) => {
    const isSelected = selectedSubValues.includes(subValue.name);

    if (isSelected) {
      setSelectedSubValues(selectedSubValues.filter((value) => value !== subValue.name));
      setValueCounts({
        ...valueCounts,
        [subValue.name]: (valueCounts[subValue.name] || 0) - 1,
      });
    } else {

      if (!isMultiSelect) {
        let filteredValues = allValues?.filter(item => item.name !== subValue.name).map(item => item.name) || []
        let newSelectedSubValues = selectedSubValues.filter(item => !filteredValues.includes(item)) || [];
        setSelectedSubValues([...newSelectedSubValues, subValue.name])
        let values = { ...valueCounts }
        filteredValues?.forEach(item => delete values[item])
        setValueCounts({
          ...values,
          [subValue.name]: (valueCounts[subValue.name] || 0) + 1,
        });
      } else {
        setSelectedSubValues([...selectedSubValues, subValue.name]);
        setValueCounts({
          ...valueCounts,
          [subValue.name]: (valueCounts[subValue.name] || 0) + 1,
        });
      }
    }
  };



  const plusItem = (subValue, maxCount) => {
    if (valueCounts[subValue.name] < maxCount) {
      setValueCounts((prevCounts) => ({
        ...prevCounts,
        [subValue.name]: (prevCounts[subValue.name] || 0) + 1,
      }));
    }
  };

  const minusItem = (subValue, minCount) => {
    if (valueCounts[subValue.name] > minCount) {
      setValueCounts((prevCounts) => ({
        ...prevCounts,
        [subValue.name]: prevCounts[subValue.name] - 1,
      }));
    }
  };

  useEffect(() => {
    setBranchId({ ...selectedBranch });
  }, [localStorage.getItem('selectedBranch')])

  return (
    <React.Fragment>

      <div className="page-content page-padding" >
        <Col lg={12}>
          <Card>
            <CardBody>
              <h6 className="mb-4 card-title">Product Details</h6>
              <Container className="mt-4">
                <Row>
                  <Col md={6} className="d-flex flex-column">
                    <Row>
                      <Col md={12}>
                        <div className="autoheight-img-container">
                          {/* Your image goes here */}
                          <Slider {...settings}>
                            {data?.Product?.image?.map((img) => {
                              return (
                                <div className="rounded rounded-5" key={img?._id}>
                                  <div className="slick-image-body">
                                    <img src={getImageSrc(img)} alt="Image 1" className="img-fluid slick-img rounded rounded-5 object-fit-cover" />
                                  </div>
                                </div>
                              )
                            })}
                          </Slider>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div className="pb-3">
                      <h2 className="mt-4">{data?.Product?.title}</h2>
                      <div>
                        <p className="text-muted">{data?.Product?.description}</p>
                      </div>
                    </div>

                    <div className="mt-auto">
                      {/* Your radio buttons and options go here */}
                      {data?.Product?.variations?.map((variation) => {
                        return (
                          <Accordion className="rounded rounded-5" key={variation?._id}>
                            <Accordion.Item className="mb-3 rounded rounded-5" eventKey="0">
                              <Accordion.Header className="rounded rounded-5">
                                {variation?.name}
                                {variation?.isMandatory && (
                                  <span className="badge-danger px-2 fw-bold font-size-11 text-capitalize custom-badge text-white">required</span>
                                )}
                              </Accordion.Header>
                              <Accordion.Body className="py-3">
                                <Row>
                                  <Col>
                                    {variation?.values?.map((subValue) => (
                                      (subValue?.isActive?.find(item => item.branchId === branchId?.value)?.isActive && <div
                                        key={subValue?.name}
                                        className="form-check"
                                      >
                                        <div>
                                          <input
                                            type="checkbox"
                                            id={subValue?.name}
                                            name="subValue"
                                            value={subValue?.name}
                                            checked={selectedSubValues.includes(subValue?.name)}
                                            onInput={() => handleSubValueChange(subValue, variation?.isMultiSelect, variation?.values)}
                                            className="form-check-input"
                                          />
                                          <div className="subVariation_div" >
                                            <label htmlFor={subValue?.name} className="form-check-label">
                                              {subValue?.name}
                                            </label>
                                            {variation?.isQuantitySelect == true && (
                                              <div className="d-flex counter">
                                                <i onClick={() => minusItem(subValue, variation?.multiCount)} className="mdi mdi-minus me-1" />
                                                <span>{valueCounts[subValue.name] || 0}</span>
                                                <i onClick={() => plusItem(subValue, variation?.quantityCount)} className="mdi mdi-plus me-1" />
                                              </div>
                                            )}

                                          </div>
                                        </div>
                                        {subValue?.price !== 0 && (
                                          <p className="curr_symbol">{CURRENCY_SYMBOL}.{subValue?.price - subValue?.discount}</p>
                                        )}
                                      </div>)
                                    ))}
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}

                      {
                        !cart?.find(item => item._id === data?.Product?._id) ?
                          <Button
                            className="page-btn"
                            onClick={() => {
                              const selectedItemsCartArray = Object.entries(valueCounts).map(([itemName, count]) => ({ name: itemName, count }));


                              if (mandatoryVariationSelected) {

                                dispatch(addProductSuccess({ ...data?.Product, totalPrice, selectedItemsCartArray }));
                                dispatch(getSubTotalPrice());
                                toast.success("Product added to cart!")
                              } else {
                                // Display an error message or take appropriate action
                                toast.error("Please add atleast one item from required variation")
                              }
                            }}
                            disabled={!mandatoryVariationSelected}
                          >
                            Add to Cart - {CURRENCY_SYMBOL}.{totalPrice}
                          </Button> :
                          <Button
                            className="page-btn"
                            onClick={() => dispatch(toggleCartSidebar(true))}
                          >
                            View Cart
                          </Button>
                      }
                    </div>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
        <NewsLetter />
      </div>
    </React.Fragment>
  )
}

ItemDetail.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
}

export default withRouter(ItemDetail)
