import React from "react"
import { useDispatch } from "react-redux"
import {
  decProductSuccess,
  getSubTotalPrice,
  incProductSuccess,
} from "store/actions"

const Counter = ({ quantity, _id }) => {
  const dispatch = useDispatch()

  const incrementHandle = () => {
    dispatch(incProductSuccess(_id))
    dispatch(getSubTotalPrice())
  }

  const decrementHandle = () => {
    dispatch(decProductSuccess(_id))
    dispatch(getSubTotalPrice())
  }

  return (
    <div className="cart-item-counter">
      <p className="counter-style" onClick={decrementHandle}>
        <i className="mdi mdi-minus"></i>
      </p>
      <span>{quantity}</span>
      <p className="counter-style" onClick={incrementHandle}>
        <i className="mdi mdi-plus"></i>
      </p>
    </div>
  )
}

export default Counter
