import "./cartSidebar.scss"
import React from "react"
import { Button, Container } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Item from "components/Cart/Item"
//SimpleBar
import SimpleBar from "simplebar-react"
import { toggleCartSidebar } from "store/actions"
import { set } from "store"
import { use } from "i18next"
import {
  toggleLoginModal,
} from "../../store/actions";


const CartSidebar = () => {
  const dispatch = useDispatch()
  const { cart, subTotalPrice } = useSelector(state => state.Cart)
  const user = JSON.parse(localStorage.getItem('authUser'))
  const openLoginModel = () => {
    if (!user) {
      dispatch(toggleLoginModal(true))
    } else {
      window.location.href = "/checkout"
    }
  }

  return (
    <>
      <div className="right-bar cart-sidebar" id="cart-sidebar">
        <SimpleBar style={{ height: '100%' }}>
          <div data-simplebar className="h-100">
            <Container className="cart-items-cont pb-2">
            <div className="d-flex align-items-center justify-content-between rightbar-title py-4">
              <h5>
                {cart?.length} Item{cart?.length > 1 && "s"} Added
              </h5>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  dispatch(toggleCartSidebar(false))
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
            </div>
              {cart?.length === 0 ? (
                <h5 className="text-center">
                  You haven't added any items in cart yet
                </h5>
              ) : (
                cart?.map(item => (
                  <Item
                    key={item?._id}
                    _id={item?._id}
                    title={item?.title}
                    price={item?.totalPrice}
                    quantity={item?.quantity}
                    image={item?.image[0]}
                    subItems={item?.selectedItemsCartArray}
                  />
                ))
              )}
            </Container>
            <div className="cart-sidebar-footer w-100 position-sticky">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <strong> Rs {subTotalPrice}</strong>
                </div>
                <Button
                  className="page-btn btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#checkoutModal"
                  onClick={() => {
                    openLoginModel();
                    dispatch(toggleCartSidebar(false));
                  }}
                >
                  Checkout
                </Button>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </>
  )
}

export default CartSidebar
