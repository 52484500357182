/* CART ACTION TYPES */
export const GET_CART_PRODUCTS = "GET_CART_PRODUCTS"
export const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS_SUCCESS"
export const GET_CART_PRODUCTS_FAIL = "GET_CART_PRODUCTS_FAIL"

export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL"

export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS"
export const REMOVE_PRODUCT_FAIL = "REMOVE_PRODUCT_FAIL"

export const INC_PRODUCT = "INC_PRODUCT"
export const INC_PRODUCT_SUCCESS = "INC_PRODUCT_SUCCESS"
export const INC_PRODUCT_FAIL = "INC_PRODUCT_FAIL"

export const DEC_PRODUCT = "DEC_PRODUCT"
export const DEC_PRODUCT_SUCCESS = "DEC_PRODUCT_SUCCESS"
export const DEC_PRODUCT_FAIL = "DEC_PRODUCT_FAIL"

export const GET_SUBTOTAL_PRICE = "GET_SUBTOTAL_PRICE"

export const CLEAR_CART = "CLEAR_CART"

export const TOGGLE_CART_SIDEBAR = "TOGGLE_CART_SIDEBAR"

export const TOGGLE_LOGIN_MODEL = "TOGGLE_LOGIN_MODEL"

export const TOGGLE_ON_FAVORITE = "TOGGLE_ON_FAVORITE"
