import { gql } from "graphql-tag";

const GET_SEO_SETTINGS_BY_ORG_ID = gql`
query GetSeoSettingsOfOrgId($organizationId: ID) {
    GetSeoSettingsOfOrgId(organizationId: $organizationId) {
      _id
      h1Heading
      keyword
      metaDescription
      metaTitle
      organizationId
      text
    }
  }
`;



export {
    GET_SEO_SETTINGS_BY_ORG_ID,
};
