import React, { createContext, useContext, useEffect, useReducer } from "react"


const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem("authUser")) || null,
  loading: false
}

export const AuthContext = createContext(INITIAL_STATE)

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
      }
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
      }
    case "LOGIN_FAILURE":
      return {
        user: null,
      }
    case "LOGOUT":
      return {
        user: null,
      }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("authUser", JSON.stringify(state.user))
  }, [state.user])

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        errors: state.errors,
        dispatch
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const UserAuth = () => {
  return useContext(AuthContext)
}
