import { show } from "dom7"
import {
  GET_CART_PRODUCTS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  REMOVE_PRODUCT_SUCCESS,
  REMOVE_PRODUCT_FAIL,
  INC_PRODUCT_SUCCESS,
  INC_PRODUCT_FAIL,
  DEC_PRODUCT_SUCCESS,
  DEC_PRODUCT_FAIL,
  GET_SUBTOTAL_PRICE,
  CLEAR_CART,
  TOGGLE_CART_SIDEBAR,
  TOGGLE_LOGIN_MODEL,
  TOGGLE_ON_FAVORITE,
} from "./actionTypes"

const INIT_STATE = {
  cart: [],
  subTotalPrice: 0.0,
  error: {},
  showCartSidebar: false,
  showLoginModal: false,
}

const Cart = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CART_PRODUCTS:
      return {
        ...state,
        cart: action.payload,
      }

    case ADD_PRODUCT_SUCCESS:
      const existingProduct = state.cart.find(
        item => item._id.toString() === action.payload._id.toString()
      )

      if (existingProduct) {
        return {
          ...state,
          cart: state.cart.map(item =>
            item._id.toString() === action.payload._id.toString()
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        }
      } else {
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }],
        }
      }

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        cart: state.cart.filter(
          item => item._id.toString() !== action.payload.toString()
        ),
      }

    case REMOVE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case INC_PRODUCT_SUCCESS:
      return {
        ...state,
        cart: state.cart.map(item =>
          item._id.toString() === action.payload.toString()
            ? {
              ...item,
              quantity: item.quantity + 1,
            }
            : item
        ),
      }

    case INC_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DEC_PRODUCT_SUCCESS:
      return {
        ...state,
        cart: state.cart.map(item =>
          item._id.toString() === action.payload.toString()
            ? {
              ...item,
              quantity: item.quantity > 1 ? item.quantity - 1 : 1,
            }
            : item
        ),
      }

    case DEC_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SUBTOTAL_PRICE:
      const subTotalPrice = state.cart.reduce((acc, item) => {
        const price = item.totalPrice
        return acc + price * item.quantity
      }, 0.0)

      return {
        ...state,
        subTotalPrice: subTotalPrice.toFixed(2),
      }

    case CLEAR_CART:
      return {
        cart: [],
        subTotalPrice: 0.0,
        error: {},
        showCartSidebar: false,
      }

    case TOGGLE_CART_SIDEBAR:
      return {
        ...state,
        showCartSidebar: action.payload,
      }

    case TOGGLE_LOGIN_MODEL:
      return {
        ...state,
        showLoginModal: action.payload,
      }

    case TOGGLE_ON_FAVORITE:
      return {
        ...state,
        showLoginModal: action.payload,
      }

    default:
      return state
  }
}

export default Cart
