import { gql } from "graphql-tag"

const GET_LOYALTY_POINTS = gql`
query LoyaltyPoints($page: Int, $perPage: Int) {
    LoyaltyPoints(page: $page, perPage: $perPage) {
      total
      loyaltyPoints {
        _id
        transactions {
          points
          type
          timestamp
        }
        points
        customerId {
          _id
        }
        organizationId {
          _id
        }
        createdAt
        createdBy {
          _id
        }
        updatedAt
        updatedBy {
          _id
        }
      }
    }
  }
`

const GET_LOYALTY_POINTS_BY_CUSTOMER = gql`
query LoyaltyPoints($loyaltyPointId: ID!, $page: Int, $perPage: Int) {
    LoyaltyPoint(id: $loyaltyPointId, page: $page, perPage: $perPage) {
      total
      loyaltyPoints {
        _id
        customerId {
          _id
        }
        organizationId {
          _id
        }
        points
        updatedAt
        createdAt
        createdBy {
          _id
        }
        updatedBy {
          _id
        }
        transactions {
          points
          timestamp
          type
        }
      }
    }
  }
`

export { GET_LOYALTY_POINTS, GET_LOYALTY_POINTS_BY_CUSTOMER }
