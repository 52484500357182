import Images from "assets/images"
import useCustomQuery from "graphql/hooks/useCustomQuery"
import { GET_FOOTER_LINKS_SETTINGS_BY_ORG_ID } from "graphql/queries/footerLinksSettings"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import PlayStore from "../../assets/images/footer/app-store.png"
import AppStore from "../../assets/images/footer/google-play.png"
import { useSelector } from "react-redux"
const Footer = () => {
  const [data, setData] = useState()
  const [colors, setColors] = useState()
  const { fetchProducts } = useSelector(state => state.GetBranchProducts)


  const getOrgId = localStorage.getItem("organizationId")
  const footerColor = sessionStorage.getItem("siteSettings")
  const branchId = localStorage.getItem("branchId")
  useEffect(() => {
    if (footerColor == undefined) {
      setColors([])
    } else {
      setColors(JSON.parse(footerColor))
    }
  }, [footerColor])

  var {
    loading,
    error,
    data: footerData,
    execute,
  } = useCustomQuery(GET_FOOTER_LINKS_SETTINGS_BY_ORG_ID, {
    variables: { organizationId: getOrgId, branchId: branchId },
  })

  useEffect(() => {
    if (footerData) {
      setData(footerData?.GetFooterLinksSettingsByOrganizationId)
    }
  }, [footerData, fetchProducts])

  const myFooterVariables = {
    Background:
      colors?.footerColors?.length > 0
        ? colors?.footerColors[0]?.value
        : "#e25730",
    PrimaryText:
      colors?.footerColors?.length > 0
        ? colors?.footerColors[1]?.value
        : "#fba96f",
    Icon:
      colors?.footerColors?.length > 0 ? colors?.footerColors[2]?.value : "",
    SecondaryText:
      colors?.footerColors?.length > 0
        ? colors?.footerColors[3]?.value
        : "#fff",
  }

  const InfoItem = ({ iconClass, content }) => (
    <div className="noti-icon align-items-center d-flex">
      <i className={iconClass}></i>
      <div>{content}</div>
    </div>
  )

  function formatTimeToAMPM(timeString) {
    if (!timeString) return "" // Return an empty string if timeString is undefined or null

    const [hour, minute] = timeString.split(":")
    const parsedHour = parseInt(hour)
    const period = parsedHour >= 12 ? "PM" : "AM"
    const formattedHour = parsedHour > 12 ? parsedHour - 12 : parsedHour
    const formattedTime = `${formattedHour}:${minute} ${period}`
    return formattedTime
  }

  const formattedTimeStart = formatTimeToAMPM(data?.startTime || "") // Provide a default empty string
  const formattedTimeEnd = formatTimeToAMPM(data?.endTime || "") // Provide a default empty string
  return (
    <React.Fragment>
      <footer
        className="footer"
        style={{
          "--footer-bg-color": myFooterVariables.Background,
          "--footer-primary-text": myFooterVariables.PrimaryText,
          "--footer-icon": myFooterVariables.Icon,
          "--footer-secondary-text": myFooterVariables.SecondaryText,
        }}
      >
        <Container fluid={true}>
          <Container>
            <div className="logo-icon position-relative">
              <img src="https://smartdemowp.com/snacky/wp-content/uploads/logo-light.png" />
            </div>
            <Row>
              <Col md={5} className="pb-0 responsive-column">
                <div className="mb-4">
                  <section className="mb-4 d-flex follow-section">
                    {[
                      "facebook",
                      "twitter",
                      "instagram",
                      "youtube",
                      "linkedin",
                    ].map(
                      (platform, index) =>
                        data?.[`${platform}Link`] && (
                          <a
                            key={index}
                            target="_blank"
                            rel="noreferrer"
                            className="btn noti-icon btn-floating m-1 d-flex justify-content-center align-items-center social-icons"
                            href={
                              (platform && data?.[`${platform}Link`]) && (data?.[`${platform}Link`]?.startsWith("http")
                                ? data?.[`${platform}Link`]
                                : `//${data?.[`${platform}Link`]}`)
                            }
                            role="button"
                          >
                            <i className={`fab fa-${platform}`}></i>
                          </a>
                        )
                    )}
                  </section>
                  <div className="app-section">
                    <h5>Company</h5>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href="/">About us</a>
                      </li>
                      <li>
                        <a href="/">Team</a>
                      </li>
                      <li>
                        <a href="/">Careers</a>
                      </li>
                      <li>
                        <a href="/">Blog</a>
                      </li>
                    </ul>
                  </div>
                  <div className="follow-section">
                    <h5>Download our Apps:</h5>
                    <section className="mb-4 d-flex">
                      <a
                        className="m-1"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          data?.playStoreLink && (data?.playStoreLink.startsWith("http")
                            ? data?.playStoreLink
                            : `//${data?.playStoreLink}`)
                        }
                        role="button"
                      >
                        <img className="app-links" src={PlayStore} />
                      </a>
                      <a
                        className="m-1"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          data?.appStoreLink && 
                          data?.appStoreLink.startsWith("http")
                            ? data?.appStoreLink
                            : `//${data?.appStoreLink}`
                        }
                        role="button"
                      >
                        <img className="app-links" src={AppStore} />
                      </a>
                    </section>
                  </div>
                </div>
              </Col>
              <Col md={4} className="responsive-column">
                <h5>Contact</h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      href={`tel:${data?.branchPhone ? data?.branchPhone : data?.orgPhone}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data?.branchPhone ? data?.branchPhone : data?.orgPhone}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${data?.branchEmail ? data?.branchEmail : data?.orgEmail}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data?.branchEmail ? data?.branchEmail : data?.orgEmail}
                    </a>
                  </li>
                  <li>
                    <InfoItem
                      content={`${formattedTimeStart}-${formattedTimeEnd}`}
                    />
                  </li>
                  <li>
                    <InfoItem
                      content={data?.branchAddress ? data?.branchAddress : data?.address}
                    />
                  </li>
                </ul>
                <div className="app-section">
                  <section className="mb-4 d-flex">
                    {[
                      "facebook",
                      "twitter",
                      "instagram",
                      "youtube",
                      "linkedin",
                    ].map(
                      (platform, index) =>
                        data?.[`${platform}Link`] && (
                          <a
                            key={index}
                            target="_blank"
                            rel="noreferrer"
                            className="btn noti-icon btn-floating m-1 d-flex justify-content-center align-items-center social-icons"
                            href={
                              (platform && data?.[`${platform}Link`]) && (data?.[`${platform}Link`]?.startsWith("http")
                                ? data?.[`${platform}Link`]
                                : `//${data?.[`${platform}Link`]}`)
                            }
                            role="button"
                          >
                            <i className={`fab fa-${platform}`}></i>
                          </a>
                        )
                    )}
                  </section>
                  <h5>Download our Apps:</h5>
                  <section className="mb-4 d-flex">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="m-1"
                      href={data?.playStoreLink}
                      role="button"
                    >
                      <img className="app-links" src={PlayStore} />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="m-1"
                      href={data?.appStoreLink}
                      role="button"
                    >
                      <img className="app-links" src={AppStore} />
                    </a>
                  </section>
                </div>
              </Col>
              <Col md={3} className="follow-section">
                <div>
                  <h5>Company</h5>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="/">About us</a>
                    </li>
                    <li>
                      <a href="/">Team</a>
                    </li>
                    <li>
                      <a href="/">Careers</a>
                    </li>
                    <li>
                      <a href="/">Blog</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>{new Date().getFullYear()} © Skoteee.</Col>
              <Col md={6}>
                <div className="text-sm-end d-none d-sm-block">
                  Design & Develop by Themesbrand
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
