import { gql } from "graphql-tag"

const GET_WEB_BANNER_FOR_CUSTOMER = gql`
query GetWebBannersForCustomer($branchId: ID, $orgId: ID) {
  GetWebBannersForCustomer(branchId: $branchId, orgId: $orgId) {
    _id
    image {
      image
      moduleType
    }
  }
}
`


export {
    GET_WEB_BANNER_FOR_CUSTOMER,
}
