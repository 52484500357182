import { Box, Tabs } from "@mui/material"
import React, { useEffect, useState } from "react"
import Scrollspy from 'react-scrollspy'

const Tab1 = ({ groups, theme }) => {
  const [colors, setColors] = useState()
  const menuColors = sessionStorage.getItem("siteSettings")

  const [activeTab, setActiveTab] = useState()

  const handleClick = (group) => {
    setActiveTab(group);
  };

  useEffect(()=>{
    setActiveTab(groups[0]?._id || null)
  },[groups])

  useEffect(() => {
    if (menuColors == undefined) {
      setColors([])
    } else {
      setColors(JSON.parse(menuColors))
    }
  }, [menuColors])

  const TabVariables = {
    Background:
      colors?.menuColors?.length > 0 ? colors?.menuColors[0]?.value : "",
    Text: colors?.menuColors?.length > 0 ? colors?.menuColors[1]?.value : "",
    Active: colors?.menuColors?.length > 0 ? colors?.menuColors[2]?.value : "",
    ActiveText:
      colors?.menuColors?.length > 0 ? colors?.menuColors[3]?.value : "",
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const idArray = groups.map(group => group._id);
  return (
    <>
      <div
        className= {theme === '2' ? 'tab2-holder' : ''}
        style={{
          '--nav-bg': TabVariables.Background,
          '--nav-text': TabVariables.Text,
          '--nav-active': TabVariables.Active,
          '--active-color': TabVariables.ActiveText,
        }}>
        <Tabs 
          variant="scrollable"  
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"  
          className= {theme === '2' ? '' : 'd-flex justify-content-center align-items-center'}>
          <Scrollspy
            onInput={handleChange}                                                                                                                                                                                                                                                     
            items= {idArray}
            currentClassName="active-button"
            className={theme === '1' ? 'tab1' : theme === '2' ? 'tab2' : theme === '3' ? 'tab1' : ''}
            value={activeTab}
          >
            {groups?.map((group)=> {
              return (
                <li key={group?._id}  className={"tab-group " + (activeTab === group?._id ? "active-button" : "")}>
                  <a 
                    value={activeTab}
                    onClick={() => handleClick(group?._id)} 
                    href={`#${group?._id}`}>
                      {group?.title}
                  </a>
                </li>
              )
            })}
          </Scrollspy>
        </Tabs>
      </div>
    </>
  )
}

export default Tab1
